'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Icon = require('../atoms/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Circle = require('../atoms/Circle');

var _Circle2 = _interopRequireDefault(_Circle);

var _color = require('../propTypes/color');

var _ThemeProvider = require('../behaviour/ThemeProvider');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * ListIcons are used to display icons in a list.
 *
 * ```example
 * <ResourceProvider>
 *   <ListIcon
 *     color="white"
 *     backgroundColor="blue"
 *     name="serving-filled"
 *   />
 * </ResourceProvider>
 * ```
 */
var ListIcon = function (_React$Component) {
  (0, _inherits3.default)(ListIcon, _React$Component);

  function ListIcon() {
    (0, _classCallCheck3.default)(this, ListIcon);
    return (0, _possibleConstructorReturn3.default)(this, (ListIcon.__proto__ || (0, _getPrototypeOf2.default)(ListIcon)).apply(this, arguments));
  }

  (0, _createClass3.default)(ListIcon, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          name = _props.name,
          backgroundColor = _props.backgroundColor,
          iconColor = _props.iconColor;

      return _react2.default.createElement(
        _Circle2.default,
        { color: backgroundColor, fill: true, height: 40, width: 40 },
        _react2.default.createElement(_Icon2.default, { color: iconColor, size: 's', name: name })
      );
    }
  }]);
  return ListIcon;
}(_react2.default.Component);

ListIcon.propTypes = {
  name: _propTypes2.default.string.isRequired,
  iconColor: _color.color,
  backgroundColor: _color.color
};


var mapThemeToProps = function mapThemeToProps(theme) {
  return {
    backgroundColor: theme.primary,
    iconColor: theme.textOnBackground
  };
};

exports.default = (0, _ThemeProvider.withTheme)(mapThemeToProps)(ListIcon);