'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getIterator2 = require('babel-runtime/core-js/get-iterator');

var _getIterator3 = _interopRequireDefault(_getIterator2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

var _Theme = require('../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Relative = require('../atoms/Relative');

var _Relative2 = _interopRequireDefault(_Relative);

var _Text = require('../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Icon = require('../atoms/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
  input: function input(showLabel) {
    return (0, _glamor.css)((0, _Text.createTextStyles)({ size: 'm' }), {
      boxSizing: 'border-box',
      height: 50,
      width: '100%',
      padding: '0 15px',
      paddingTop: showLabel ? 10 : 0,
      transition: 'padding-top .225s ease-out',
      border: 0,
      '&:-webkit-autofill ~ div': {
        opacity: '1 !important',
        top: '8px !important'
      },
      '&:-webkit-autofill': {
        paddingTop: '10px !important'
      }
    });
  },
  area: function area(textColor, lines, showLabel) {
    return (0, _glamor.css)((0, _Text.createTextStyles)({ size: 'm' }), {
      boxSizing: 'border-box',
      transition: 'padding-top .225s ease-out',
      height: 'calc(30px*' + lines + ')',
      width: '100%',
      padding: '10px 15px',
      paddingTop: showLabel ? 20 : 10,
      fontSize: '14px',
      border: 0,
      ':invalid:focus': {
        color: 'red'
      }
    });
  },
  error: (0, _glamor.css)({
    backgroundColor: '#c1392b',
    position: 'absolute',
    bottom: '100%',
    left: '0',
    zIndex: 40,
    width: '100%',
    padding: '12px 15px 15px',
    borderRadius: '2px',
    boxShadow: '0 1px 2px 1px rgba(0,0,0,.25)'
  }),
  arrow: (0, _glamor.css)({
    position: 'absolute',
    bottom: '-10px',
    left: '50%',
    marginLeft: '-10px',
    display: 'block',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '10px solid #c1392b'
  }),
  required: (0, _glamor.css)({
    position: 'absolute',
    right: 10
  }),
  label: (0, _glamor.css)({
    position: 'absolute',
    left: 15,
    fontSize: 10,
    opacity: 0,
    transition: 'all .225s ease-out'
  }),
  placeholder: (0, _glamor.css)({
    position: 'absolute',
    bottom: 2,
    right: 15
  })
};

var InputError = function InputError(_ref) {
  var children = _ref.children,
      props = (0, _objectWithoutProperties3.default)(_ref, ['children']);
  return _react2.default.createElement(
    _View2.default,
    (0, _extends3.default)({}, styles.error, props),
    _react2.default.createElement(
      _Text2.default,
      { color: 'textOnBackground' },
      children
    ),
    _react2.default.createElement(_View2.default, styles.arrow)
  );
};

InputError.propTypes = {
  children: _propTypes2.default.string.isRequired

  /**
   * TextInputs are used to allow users to enter information like names, numbers, urls, email addresses or passwords.
   *
   * ```example
   * <TextInput name="email" type="email" placeholder="Your email" required />
   * <TextInput name="inquiry" lines={5} placeholder="Your question" maxLength={255} minLength={50} />
   * ```
   */
};
var Input = function (_React$Component) {
  (0, _inherits3.default)(Input, _React$Component);

  function Input() {
    var _ref2;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Input);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref2 = Input.__proto__ || (0, _getPrototypeOf2.default)(Input)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
      value: '',
      visible: true,
      message: null,
      length: _this.props.value && _this.props.value.length || 0
    }, _this.handleInvalid = function (e) {
      e.preventDefault();
      var _this$context = _this.context,
          STATES = _this$context.STATES,
          validity = _this$context.validity;

      var message = e.target.validationMessage;
      var hasState = false;
      if (STATES) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = (0, _getIterator3.default)(STATES), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var state = _step.value;

            if (e.target.validity[state]) {
              _this.setState({ message: validity[state] || message });
              hasState = true;
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
      !hasState && _this.setState({ message: message });
    }, _this.setInput = function (input) {
      if (input) {
        _this.setState({ length: input.value && input.value.length });
        input.addEventListener('invalid', _this.handleInvalid);
      } else if (_this.input) {
        _this.input.removeEventListener('invalid', _this.handleInvalid);
      }
      _this.input = input;
      _this.props.onInputRef(input);
    }, _this.handleChange = function (e) {
      _this.setState({
        value: e.target.value,
        message: null,
        length: e.target.value.length
      });
      _this.props.onChange && _this.props.onChange(e);
    }, _this.handleMessageClick = function () {
      return _this.setState({ message: null });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Input, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          required = _props.required,
          onInputRef = _props.onInputRef,
          lines = _props.lines,
          label = _props.label,
          pattern = _props.pattern,
          props = (0, _objectWithoutProperties3.default)(_props, ['required', 'onInputRef', 'lines', 'label', 'pattern']);

      var currentValue = this.props.value || this.state.value;
      var labelVisible = currentValue.length > 0;
      var showLabel = label && currentValue.length > 0;

      return _react2.default.createElement(
        _Theme2.default,
        null,
        function (_ref3) {
          var theme = _ref3.theme,
              colorize = _ref3.colorize;
          return _react2.default.createElement(
            _Relative2.default,
            { style: { width: '100%' } },
            _this2.state.message && _react2.default.createElement(
              InputError,
              { onClick: _this2.handleMessageClick },
              _this2.state.message
            ),
            lines === 1 ? _react2.default.createElement('input', (0, _extends3.default)({
              ref: _this2.setInput
            }, styles.input(showLabel), {
              required: required,
              'aria-required': required
            }, props, {
              onInvalid: _this2.handleInvalid,
              onKeyUp: _this2.handleChange,
              pattern: pattern
            })) : _react2.default.createElement('textarea', (0, _extends3.default)({}, styles.area(theme.secondaryText, lines, showLabel), props, {
              ref: onInputRef,
              onChange: _this2.handleChange
            })),
            label && _react2.default.createElement(
              _View2.default,
              (0, _extends3.default)({}, styles.label, {
                style: {
                  opacity: labelVisible ? 1 : 0,
                  top: labelVisible ? 8 : 12
                }
              }),
              _react2.default.createElement(
                _Text2.default,
                { color: 'secondaryText', size: 'xs' },
                label,
                ' ',
                required && '*'
              )
            ),
            pattern && _this2.input && _this2.input.validity && _this2.input.validity.valid && _react2.default.createElement(
              _View2.default,
              (0, _glamor.css)({
                position: 'absolute',
                top: 16,
                right: 15,
                pointerEvents: 'none'
              }),
              _react2.default.createElement(_Icon2.default, { name: 'checkFilled', size: 'xs', color: 'lightGrey' })
            ),
            props.maxLength && _react2.default.createElement(
              _View2.default,
              styles.placeholder,
              _react2.default.createElement(
                _Text2.default,
                { color: 'secondaryText', size: 's' },
                _this2.state.length,
                '/',
                props.maxLength
              )
            )
          );
        }
      );
    }
  }]);
  return Input;
}(_react2.default.Component);

Input.propTypes = {
  /** The default value to put into the component, without making it controlled */
  defaultValue: _propTypes2.default.string,
  /** Indicates that this field is required */
  required: _propTypes2.default.bool,
  /** The name of this input field */
  name: _propTypes2.default.string.isRequired,
  /** The label of the input */
  label: _propTypes2.default.string,
  /** Type, can be: 'tel', 'number', 'text', 'url', 'email' */
  type: _propTypes2.default.oneOf(['tel', 'number', 'text', 'url', 'email', 'password', 'date', 'datetime-local']),
  /** Called, when the users changes something */
  onChange: _propTypes2.default.func,
  /** The value, makes this component a controlled component */
  value: _propTypes2.default.string,
  /** Can only be used with type=text. Increase to enable multi-line input */
  lines: _propTypes2.default.number,
  /** Regular expression to validate against */
  pattern: _propTypes2.default.string,
  /** Min number of characters that must be provided */
  minLength: _propTypes2.default.number,
  /** Max number of characters that can be provided */
  maxLength: _propTypes2.default.number,
  /** Called with the input field a reference */
  onInputRef: _propTypes2.default.func
};
Input.defaultProps = {
  required: false,
  lines: 1,
  onInputRef: function onInputRef(_) {
    return _;
  },
  type: 'text'
};


Input.contextTypes = {
  validity: _propTypes2.default.object,
  STATES: _propTypes2.default.array
};

exports.default = Input;