'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

var _color = require('../propTypes/color');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var line = function line(color) {
  return (0, _glamor.css)({
    backgroundColor: color,
    height: '1px',
    width: '100%'
  });
};

/** Hello, I'm a Line */
var Line = function Line(_ref) {
  var color = _ref.color;
  return _react2.default.createElement(_View2.default, (0, _extends3.default)({}, line((0, _color.colorCode)(color)), {
    alignV: 'center',
    alignH: 'center',
    direction: 'column'
  }));
};

Line.propTypes = {
  /** Color of the Circle */
  color: _color.color
};

exports.default = Line;