'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _glamor = require('glamor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * This Component is rebuild of angular-material's flexbox directives.
 *
 * Different to angular's directive implementation, which can be used independent of each other,
 * this component combines layout and element attributes within one component.
 *
 * For explanation see:
 * - https://material.angularjs.org/latest/layout/alignment
 * - https://material.angularjs.org/latest/layout/children
 *
 * @author Alex Tobies <alex.tobies@allthings.me>
 * @author Daniel Lehr <daniel.lehr@allthings.me>
 *
 * @TODO: add flexOrder
 */

var View = function (_Component) {
  _inherits(View, _Component);

  function View() {
    _classCallCheck(this, View);

    return _possibleConstructorReturn(this, (View.__proto__ || Object.getPrototypeOf(View)).apply(this, arguments));
  }

  _createClass(View, [{
    key: 'getCssAlignValue',


    /**
     * Appends 'flex-' to 'start' and 'end'
     * @param {String} alignment
     * @return {String}
     */
    value: function getCssAlignValue(alignment) {
      if (alignment === 'start' || alignment === 'end') {
        return 'flex-' + alignment;
      }
      return alignment;
    }

    /**
     *
     * @param {String|Number} flex
     * @returns {Object}
     */

  }, {
    key: 'getCssFlexValue',
    value: function getCssFlexValue(flex) {
      if (typeof flex === 'number') {
        if (flex === 33) flex = 100 / 3;
        if (flex === 66) flex = 200 / 3;
        return '1 1 ' + flex + '%';
      }

      /**
       * CSS value of flex: flex-grow flex-shrink flex-basis
       */
      switch (flex) {
        case 'none':
          return '0 0 auto';
        case 'flex':
          return '1';
        case 'nogrow':
          return '0 1 auto';
        case 'grow':
          return '1 1 100%';
        case 'initial':
          return '0 1 auto';
        case 'auto':
          return '1 1 auto';
        case 'noshrink':
          return '1 0 auto';
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          alignH = _props.alignH,
          alignV = _props.alignV,
          children = _props.children,
          direction = _props.direction,
          fill = _props.fill,
          flex = _props.flex,
          onRef = _props.onRef,
          wrap = _props.wrap,
          restProps = _objectWithoutProperties(_props, ['alignH', 'alignV', 'children', 'direction', 'fill', 'flex', 'onRef', 'wrap']);

      var styles = {};

      if (direction || flex) {
        styles.boxSizing = 'border-box';
      }

      if (direction) {
        styles = _extends({}, styles, {
          alignContent: this.getCssAlignValue(alignV),
          alignItems: this.getCssAlignValue(alignV),
          display: 'flex',
          flexDirection: direction,
          justifyContent: this.getCssAlignValue(alignH)
        });

        if (wrap) styles.flexWrap = wrap;

        if (fill) {
          styles = _extends({}, styles, {
            height: '100%',
            margin: 0,
            minHeight: '100%',
            width: '100%'
          });
        }
      }

      if (flex) {
        styles.flex = this.getCssFlexValue(flex);
      }

      return _react2.default.createElement(
        'div',
        _extends({ ref: onRef }, (0, _glamor.css)(styles), restProps),
        children
      );
    }
  }]);

  return View;
}(_react.Component);

View.propTypes = {
  children: _propTypes2.default.node,

  /**
   * Layout for children
   */
  // alignment horizontal
  alignH: _propTypes2.default.oneOf(['none', 'start', 'center', 'end', 'space-around', 'space-between']),
  // alignment vertical
  alignV: _propTypes2.default.oneOf(['none', 'start', 'center', 'end', 'stretch']),
  // direction
  direction: _propTypes2.default.oneOf(['row', 'column']),
  // fill
  fill: _propTypes2.default.bool,
  // wrap
  wrap: _propTypes2.default.oneOf(['inherit', 'initial', 'wrap', 'nowrap', 'wrap-reverse']),

  /**
   * Element
   */
  flex: _propTypes2.default.oneOf(['none', 'flex', 'nogrow', 'grow', 'initial', 'auto', 'noshrink', 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 95, 90, 100, 33, 66]),
  onRef: _propTypes2.default.func
};
View.defaultProps = {
  alignH: 'start',
  alignV: 'stretch',
  fill: false,
  flex: 'none',
  onRef: function onRef(_) {
    return _;
  } };
exports.default = View;