'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Column = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Column = function Column(_ref) {
  var children = _ref.children,
      props = (0, _objectWithoutProperties3.default)(_ref, ['children']);
  return _react2.default.createElement(
    _View2.default,
    (0, _extends3.default)({ flex: 'flex' }, props, { direction: 'column' }),
    children
  );
};

exports.Column = Column;
Column.propTypes = {
  children: _propTypes2.default.node.isRequired
};

var layout = function layout(columnNumber) {
  return (0, _glamor.css)({
    maxWidth: columnNumber === 0 && '320px',
    overflow: columnNumber === 0 && 'hidden',
    position: 'relative', // for box-shadow to work properly
    boxShadow: columnNumber === 1 && '-2px 0px 5px 0px rgba(0,0,0,0.2)'
  });
};

var ColumnLayout = function (_React$Component) {
  (0, _inherits3.default)(ColumnLayout, _React$Component);

  function ColumnLayout() {
    (0, _classCallCheck3.default)(this, ColumnLayout);
    return (0, _possibleConstructorReturn3.default)(this, (ColumnLayout.__proto__ || (0, _getPrototypeOf2.default)(ColumnLayout)).apply(this, arguments));
  }

  (0, _createClass3.default)(ColumnLayout, [{
    key: 'render',
    value: function render() {
      var children = this.props.children;


      return _react2.default.createElement(
        _View2.default,
        { flex: 'flex', direction: 'row' },
        _react2.default.Children.map(children, function (child, i) {
          return _react2.default.createElement(
            Column,
            (0, _extends3.default)({}, layout(i), { key: i }),
            child
          );
        })
      );
    }
  }]);
  return ColumnLayout;
}(_react2.default.Component);

ColumnLayout.propTypes = {
  children: _propTypes2.default.node.isRequired
};
exports.default = ColumnLayout;