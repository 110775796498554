const env = NODE_ENV || 'production'

const pv = {
  apiVersion: 'v1',
  // Does not work for dev, as privera just accepts requests from swisscloud.
  // staging: https://privera-mieterdossier.scapp.io)
  user: PRIVERA_USER,
  pwd: PRIVERA_PWD,
  backendHost: PRIVERA_HOST,
  assetsUrl: ASSETS_URL,
}

export const PV_API_ROOT = `${pv.backendHost}/${pv.apiVersion}`
export const PV_AUTH = btoa(`${pv.user}:${pv.pwd}`)
export const PV_ASSETS_URL = `${pv.assetsUrl}`
