import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { connect } from 'redux-zero/react'
import actions from '../actions'
import HeaderBar from '../components/HeaderBar'
import { messages } from '../helper/messages'
import { getLocalDate } from '../helper/helpers'
import ListValueItem from '../components/ListValueItem'
import NoContentPage from './NoContentPage'
import {
  GroupedCardList,
  ListSpinner,
  SimpleLayout,
  Spacer,
  Text,
  View,
} from '@allthings/elements'

const tenantKeys = [
  'birthday',
  'marital_status',
  'phone_home',
  'phone_business',
  'phone_mobile',
  'email',
  'iban',
]

class Tenants extends React.Component {
  static propTypes = {
    getTenants: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    match: PropTypes.object,
    locale: PropTypes.string.isRequired,
    setTenantsLoading: PropTypes.func.isRequired,
    tenants: PropTypes.array.isRequired,
  }

  componentWillMount() {
    this.props.setTenantsLoading()
  }

  componentDidMount() {
    this.props.getTenants(this.props.match.params.id)
  }

  formatMessage = (message, options) =>
    this.props.intl.formatMessage(message, options)

  renderTenants() {
    const { tenants, locale } = this.props
    return tenants && tenants.length
      ? tenants.map((tenant, i) => {
          const title = `${tenant.title} ${tenant.additional_title || ''} ${
            tenant.first_name
          } ${tenant.last_name}`
          return (
            <GroupedCardList key={tenant.id} title={title}>
              {tenantKeys.map((key, i) => {
                const val =
                  key === 'birthday'
                    ? getLocalDate(tenant[key], locale)
                    : tenant[key] || '--'
                return (
                  <ListValueItem
                    key={key}
                    label={this.formatMessage(messages[key])}
                  >
                    <Text>{val}</Text>
                  </ListValueItem>
                )
              })}
            </GroupedCardList>
          )
        })
      : null
  }

  renderContent() {
    const { tenants } = this.props

    if (tenants.loading) {
      return (
        <View fill alignH="center" alignV="center">
          <Spacer height={20} />
          <ListSpinner />
        </View>
      )
    } else {
      return tenants && tenants.length ? (
        <Fragment>{this.renderTenants()}</Fragment>
      ) : (
        <NoContentPage />
      )
    }
  }

  render() {
    return (
      <Fragment>
        <HeaderBar
          label={this.formatMessage(messages.tenantsHeader)}
          lBtnIcon="arrow-left"
          lBtnTarget="/"
        />
        <SimpleLayout flex="auto">{this.renderContent()}</SimpleLayout>
      </Fragment>
    )
  }
}

const mapToProps = (state, ownProps) => {
  return {
    locale: state.locale,
    tenants: state.tenants.tenants.filter(tenant => {
      return tenant.id.toLowerCase() !== state.tenantOID.toLowerCase()
    }),
  }
}

export default injectIntl(connect(mapToProps, actions)(Tenants))
