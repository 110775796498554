import PropTypes from 'prop-types'
import React from 'react'
import { css } from 'glamor'

const styles = {
  box: css({
    margin: 'auto',
    background: '#fff',
    maxWidth: 760,
    width: '100%',
    padding: 40,
    borderRadius: 2,
    wordBreak: 'break-word',
    height: '100%',
  }),
}

const ImageColumn = ({
  children,
  source,
  width,
  height,
  onImageClick = noop => noop,
}) => {
  return (
    <div {...styles}>
      {source ? <img src={source} width={width} height={height} /> : children}
    </div>
  )
}

ImageColumn.propTypes = {
  children: PropTypes.node,
  onImageClick: PropTypes.func,
  source: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

ImageColumn.defaultPropTypes = {
  width: '128',
  height: '128',
}

export default ImageColumn
