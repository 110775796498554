'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var absolute = function absolute(_ref) {
  var left = _ref.left,
      right = _ref.right,
      top = _ref.top,
      bottom = _ref.bottom;
  return (0, _glamor.css)({
    position: 'absolute',
    top: top,
    right: right,
    left: left,
    bottom: bottom
  });
};

var Absolute = function (_React$Component) {
  (0, _inherits3.default)(Absolute, _React$Component);

  function Absolute() {
    (0, _classCallCheck3.default)(this, Absolute);
    return (0, _possibleConstructorReturn3.default)(this, (Absolute.__proto__ || (0, _getPrototypeOf2.default)(Absolute)).apply(this, arguments));
  }

  (0, _createClass3.default)(Absolute, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          top = _props.top,
          left = _props.left,
          right = _props.right,
          bottom = _props.bottom,
          children = _props.children,
          props = (0, _objectWithoutProperties3.default)(_props, ['top', 'left', 'right', 'bottom', 'children']);

      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({}, absolute({ top: top, left: left, right: right, bottom: bottom }), props),
        children
      );
    }
  }]);
  return Absolute;
}(_react2.default.Component);

Absolute.propTypes = {
  /** Absolute children */
  children: _propTypes2.default.node,
  /** Top offset */
  top: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  /** Bottom offset */
  bottom: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  /** Left offset */
  left: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  /** Right offset */
  right: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number])
};
exports.default = Absolute;