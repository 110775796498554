'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _glamor = require('glamor');

var _Icon = require('../../atoms/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _colors = require('@allthings/colors');

var _ListItem = require('./ListItem');

var _ListItem2 = _interopRequireDefault(_ListItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var style = (0, _glamor.css)({
  width: 12,
  height: 12
});

function ChevronRightListItem(props) {
  var children = props.children,
      _props$flex = props.flex,
      flex = _props$flex === undefined ? 'grow' : _props$flex,
      _props$innerStyle = props.innerStyle,
      innerStyle = _props$innerStyle === undefined ? {} : _props$innerStyle,
      restProps = (0, _objectWithoutProperties3.default)(props, ['children', 'flex', 'innerStyle']);

  return _react2.default.createElement(
    _ListItem2.default,
    (0, _extends3.default)({}, restProps, { flex: flex }),
    _react2.default.createElement(
      _View2.default,
      (0, _extends3.default)({
        direction: 'column',
        flex: flex
      }, (0, _glamor.merge)((0, _glamor.css)(innerStyle), (0, _glamor.css)({ width: '100%' }))),
      children
    ),
    _react2.default.createElement(
      _View2.default,
      { flex: 'nogrow' },
      _react2.default.createElement(_Icon2.default, (0, _extends3.default)({ name: 'arrow-right', size: 'xs', color: _colors.ColorPalette.greyIntense }, style))
    )
  );
}

ChevronRightListItem.propTypes = {
  children: _propTypes2.default.node.isRequired,
  flex: _propTypes2.default.node,
  innerStyle: _propTypes2.default.object
};

exports.default = ChevronRightListItem;