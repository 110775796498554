'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESOURCE_PATH = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RESOURCE_PATH = exports.RESOURCE_PATH = 'https://static.allthings.me';

/**
 * Elements uses a set of static resources like images or icons.
 * In order to benefit from caching across all apps, these resources are provided by a static asset CDN.
 *
 * Whenever you like to use Icons or Illustrations, you need to use the ResouceProvider to let the components know where they are.
 */

var ResourceProvider = function (_React$Component) {
  (0, _inherits3.default)(ResourceProvider, _React$Component);

  function ResourceProvider() {
    (0, _classCallCheck3.default)(this, ResourceProvider);
    return (0, _possibleConstructorReturn3.default)(this, (ResourceProvider.__proto__ || (0, _getPrototypeOf2.default)(ResourceProvider)).apply(this, arguments));
  }

  (0, _createClass3.default)(ResourceProvider, [{
    key: 'getChildContext',
    value: function getChildContext() {
      return { resourcePath: this.props.resourcePath };
    }
  }, {
    key: 'render',
    value: function render() {
      return this.props.children;
    }
  }]);
  return ResourceProvider;
}(_react2.default.Component);

ResourceProvider.childContextTypes = {
  resourcePath: _propTypes2.default.string
};
ResourceProvider.propTypes = {
  resourcePath: _propTypes2.default.string.isRequired,
  children: _propTypes2.default.node.isRequired
};
ResourceProvider.defaultProps = {
  resourcePath: RESOURCE_PATH
};
exports.default = ResourceProvider;