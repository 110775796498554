import PropTypes from 'prop-types'
import React from 'react'
import { CardButton, Text } from '@allthings/elements'
import PhoneIcon from '@allthings/react-ionicons/lib/IosTelephoneOutlineIcon'

const iconStyle = {
  width: 18,
  height: 18,
  margin: '0 5',
}

export default class PhoneButtonLink extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    phone: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.onButtonClicked = this.onButtonClicked.bind(this)
  }

  // calling number
  onButtonClicked() {
    window.open(`tel:${this.props.phone}`, '_parent')
  }

  render() {
    return (
      <CardButton onClick={this.onButtonClicked}>
        <PhoneIcon style={iconStyle} />
        <Text>{this.props.phone}</Text>
        {this.props.children}
      </CardButton>
    )
  }
}
