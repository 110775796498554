import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedHTMLMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'redux-zero/react'

import {
  ChevronRightListItem,
  GroupedCardList,
  Hero,
  ListSpinner,
  SimpleLayout,
  Spacer,
  Text,
  View,
  Icon,
} from '@allthings/elements'
import { ColorPalette } from '@allthings/colors'

import actions from '../actions'
import NoAccessPage from './NoAccessPage'
import { messages } from '../helper/messages'
import { getTenancyTitle } from '../helper/helpers'
import { PV_ASSETS_URL } from '../helper/api-config'

const styles = {
  margin: 10,
}
const stylesTemplates = {
  background: '#8f9e6a',
  padding: 10,
  margin: 10,
  'text-decoration': 'none',
}

class Home extends React.Component {
  static propTypes = {
    getTenant: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    tenantOID: PropTypes.string.isRequired,
    tenant: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.getTenant()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.tenantOID &&
      !this.props.tenant.id &&
      this.props.tenant.status !== 'error'
    ) {
      this.props.getTenant()
    }
  }

  formatMessage = (message, options) =>
    this.props.intl.formatMessage(message, options)

  getTitle(messages, tenancy) {
    const title = []
    title.push(`${tenancy.type} ${tenancy.street}`)
    if (tenancy.floor) {
      title.push(this.formatMessage(messages.floor, { num: tenancy.floor }))
    }
    if (tenancy.area) {
      title.push(this.formatMessage(messages.area, { num: tenancy.area }))
    }
    if (tenancy.rooms) {
      title.push(this.formatMessage(messages.rooms, { num: tenancy.rooms }))
    }

    return title.join(', ')
  }

  renderTenancies() {
    const { tenant } = this.props

    if (tenant.loading) {
      return (
        <View fill alignH="center" alignV="center">
          <ListSpinner />
        </View>
      )
    } else {
      return tenant.tenancies && tenant.tenancies.length
        ? tenant.tenancies.map((tenancy, i) => {
            return (
              <GroupedCardList
                key={tenancy.id}
                title={getTenancyTitle(this.formatMessage, tenancy)}
              >
                <Link to={`/tenancy/${tenancy.id}`}>
                  <ChevronRightListItem>
                    <Text>{this.formatMessage(messages.tenancyHeader)}</Text>
                  </ChevronRightListItem>
                </Link>
                <Link to={`/tenancy/${tenancy.id}/contacts`}>
                  <ChevronRightListItem>
                    <Text>
                      {this.formatMessage(messages.contactPersonsHeader)}
                    </Text>
                  </ChevronRightListItem>
                </Link>
                <Link to={`/tenancy/${tenancy.id}/tenants`}>
                  <ChevronRightListItem>
                    <Text>{this.formatMessage(messages.tenantsHeader)}</Text>
                  </ChevronRightListItem>
                </Link>
              </GroupedCardList>
            )
          })
        : null
    }
  }

  render() {
    const { tenant, tenantOID } = this.props

    if (!tenant || tenant.status === 'error') {
      return <NoAccessPage />
    } else {
      return (
        <Fragment>
          <Hero
            img={`${PV_ASSETS_URL}/img/my-contracts.png`}
            className="hero-img"
          >
            <Text color={ColorPalette.white}>
              <FormattedHTMLMessage
                id="home.hero-text"
                description="The text that is shown besides the hero-images in the header-section."
                defaultMessage="View your current<br/><strong>contracts, personal data & balances</strong><br/>and manage it."
              />
            </Text>
          </Hero>
          <Spacer height={20} />
          <SimpleLayout flex="auto">
            <Link to="/tenant">
              <ChevronRightListItem>
                <Text>{this.formatMessage(messages.tenantHeader)}</Text>
              </ChevronRightListItem>
            </Link>
            <Link to="/tenant/account">
              <ChevronRightListItem>
                <Text>{this.formatMessage(messages.tenantAccountHeader)}</Text>
              </ChevronRightListItem>
            </Link>
            {this.renderTenancies()}
            <GroupedCardList title={this.formatMessage(messages.noticeHeader)}>
              <ChevronRightListItem>
                <Text>{this.formatMessage(messages.canceling)}</Text>
                <View direction="row" alignH="start" style={styles}>
                  <a
                    href="https://www.privera.ch/_file/1611/briefvorlage-kuendigung.docx"
                    target="_blank"
                    style={stylesTemplates}
                  >
                    <View direction="row" alignH="start" alignV="center">
                      <Icon name="download" size="s" color="white" />
                      <Text color="white">
                        {this.formatMessage(messages.canceling_template) +
                          ' DE'}
                      </Text>
                    </View>
                  </a>

                  <a
                    href="https://www.privera.ch/_file/1613/lettre-modele-de-resiliation.docx"
                    target="_blank"
                    style={stylesTemplates}
                  >
                    <View direction="row" alignH="start" alignV="center">
                      <Icon name="download" size="s" color="white" />
                      <Text color="white">
                        {this.formatMessage(messages.canceling_template) +
                          ' FR'}
                      </Text>
                    </View>
                  </a>
                  <a
                    href="https://www.privera.ch/_file/1612/lettera-modello-di-disdetta.docx"
                    target="_blank"
                    style={stylesTemplates}
                  >
                    <View direction="row" alignH="start" alignV="center">
                      <Icon name="download" size="s" color="white" />
                      <Text color="white">
                        {this.formatMessage(messages.canceling_template) +
                          ' IT'}
                      </Text>
                    </View>
                  </a>
                </View>
              </ChevronRightListItem>
            </GroupedCardList>
          </SimpleLayout>
        </Fragment>
      )
    }
  }
}

const mapToProps = (state, ownProps) => {
  return {
    tenant: state.tenant,
    tenantOID: state.tenantOID,
  }
}

export default injectIntl(connect(mapToProps, actions)(Home))
