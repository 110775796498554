'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = CardFooter;

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _glamor = require('glamor');

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var style = (0, _glamor.css)({
  borderTop: '1px solid #e7ecee',
  '> *': {
    padding: '10px 13px',
    textAlign: 'center'
  },
  '> :not(:last-child)': {
    borderRight: '1px solid #e7ecee'
  }
});

function CardFooter(_ref) {
  var children = _ref.children;

  return _react2.default.createElement(
    _View2.default,
    (0, _extends3.default)({ direction: 'row', alignV: 'center', alignH: 'space-around' }, style),
    children
  );
}

CardFooter.propTypes = {
  children: _propTypes2.default.node
};