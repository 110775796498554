import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { css } from 'glamor'
import { connect } from 'redux-zero/react'
import actions from '../actions'
import HeaderBar from '../components/HeaderBar'
import TextInput from '../components/TextInput'
import RadioButtonSet from '../components/RadioButtonSet'
import Form from '../components/Form'
import { messages } from '../helper/messages'
import {
  Button,
  Card,
  CardContent,
  Icon,
  List,
  ListItem,
  ListSpinner,
  SimpleLayout,
  Spacer,
  Text,
  View,
} from '@allthings/elements'
import { ColorPalette, alpha } from '@allthings/colors'

class TenantEdit extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object,
    intl: PropTypes.object,
    me: PropTypes.object,
    props: PropTypes.object,
    setTenantData: PropTypes.func,
    setFormLoading: PropTypes.func,
    tenant: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      formSent: false,
      formPending: false,
    }
  }

  componentDidMount() {
    this.props.form.data = this.props.tenant
    this.props.setFormLoading(false)
  }

  componentDidUpdate(prevProps, nextProps) {
    if (this.state.formSent && !this.props.form.pending) {
      this.handleResponse()
    }
  }

  formatMessage = (message, options) =>
    this.props.intl.formatMessage(message, options)

  handleSubmit = (e, data) => {
    // Date musst be in iso-format (2018-02-15T12:00:00.190Z)
    data.birthday = new Date(`${data.birthday}T12:00:00Z`).toISOString()

    // empty fields must be null instead of ''
    data.phone_home = data.phone_home || null
    data.phone_business = data.phone_business || null
    data.phone_mobile = data.phone_mobile || null
    data.email = data.email || null
    data.iban = data.iban || null

    // Remove submit-button from data-payload
    delete data.submit
    this.setState({ formSent: true })
    this.props.setTenantData(data)
  }

  handleResponse = () => {
    // Redirect to /tenant if form response was success
    if (this.props.form.status === 'success') {
      this.props.history.push('/tenant')
    }
  }

  renderForm() {
    const { tenant, form } = this.props
    // privera expects exatct match: +41 00 000 00 00
    const phonePattern = '^\\+41\\s\\d{2}\\s\\d{3}\\s\\d{2}\\s\\d{2}$'
    // privera expects exatct match: CH00 0000 0000 0000 0000 0
    const ibanPattern =
      '^CH\\d{2}\\s\\d{4}\\s\\d{4}\\s\\d{4}\\s\\d{4}\\s\\w{1}$'
    let bDay = []
    if (tenant.birthday) {
      bDay = tenant.birthday.split('T')[0]
    }

    const salutation = [
      { key: 'mr', value: this.formatMessage(messages.salutationMr) },
      { key: 'mrs', value: this.formatMessage(messages.salutationMrs) },
    ]

    if (form.loading) {
      return (
        <View fill alignH="center" alignV="center">
          <Spacer height={20} />
          <ListSpinner />
        </View>
      )
    } else {
      return (
        <Fragment>
          {form.status === 'error' && (
            <Card>
              <CardContent
                style={{ backgroundColor: alpha(ColorPalette.red, 0.25) }}
              >
                {Object.keys(form.message).map(key => {
                  return <Text key={key}>{form.message[key]}</Text>
                })}
              </CardContent>
            </Card>
          )}
          <Spacer height={46} />
          <Form onSubmit={this.handleSubmit}>
            <Card>
              <List>
                <input type="hidden" name="id" value={tenant.id} />
                <RadioButtonSet
                  name="title"
                  selection={salutation}
                  defaultValue={tenant.title}
                />
                <TextInput
                  {...css({ height: 50 })}
                  type="date"
                  name="birthday"
                  label={this.formatMessage(messages.birthday)}
                  placeholder={this.formatMessage(messages.birthday)}
                  defaultValue={bDay}
                  inputStyles={css({
                    height: '20px !important',
                    position: 'relative',
                    left: '14px',
                    marginTop: '20px',
                    padding: '0 50px 0 0 !important',
                  })}
                  required
                />
                <TextInput
                  name="phone_home"
                  label={this.formatMessage(messages.phone_home)}
                  placeholder={this.formatMessage(messages.phone_home)}
                  minLength={3}
                  defaultValue={tenant.phone_home}
                  validityMessage={this.formatMessage(
                    messages.phoneValidityMessage
                  )}
                  pattern={phonePattern}
                />
                <TextInput
                  name="phone_business"
                  label={this.formatMessage(messages.phone_business)}
                  placeholder={this.formatMessage(messages.phone_business)}
                  minLength={3}
                  defaultValue={tenant.phone_business}
                  validityMessage={this.formatMessage(
                    messages.phoneValidityMessage
                  )}
                  pattern={phonePattern}
                />
                <TextInput
                  name="phone_mobile"
                  label={this.formatMessage(messages.phone_mobile)}
                  placeholder={this.formatMessage(messages.phone_mobile)}
                  minLength={3}
                  defaultValue={tenant.phone_mobile}
                  pattern={phonePattern}
                  validityMessage={this.formatMessage(
                    messages.phoneValidityMessage
                  )}
                />
                <TextInput
                  type="email"
                  name="email"
                  label={this.formatMessage(messages.email)}
                  placeholder={this.formatMessage(messages.email)}
                  defaultValue={tenant.email}
                />
                <TextInput
                  name="iban"
                  label={this.formatMessage(messages.iban)}
                  placeholder={this.formatMessage(messages.iban)}
                  defaultValue={tenant.iban}
                  pattern={ibanPattern}
                  validityMessage={this.formatMessage(
                    messages.ibanValidityMessage
                  )}
                />
                <ListItem alignH="center">
                  {form.pending ? (
                    <ListSpinner />
                  ) : (
                    <Button type="submit">
                      <View direction="row">
                        <FormattedMessage
                          id="button.send"
                          description="The label of the form submit button"
                          defaultMessage="Send"
                        />
                        <View style={{ marginLeft: 10 }}>
                          <Icon name="send-filled" size="xs" color="white" />
                        </View>
                      </View>
                    </Button>
                  )}
                </ListItem>
              </List>
            </Card>
          </Form>
        </Fragment>
      )
    }
  }

  render() {
    return (
      <Fragment>
        <HeaderBar
          label={this.formatMessage(messages.tenantEditHeader)}
          rBtnIcon="remove-light-filled"
          rBtnTarget="/tenant"
        />
        <SimpleLayout flex="auto">{this.renderForm()}</SimpleLayout>
      </Fragment>
    )
  }
}

const mapToProps = (state, ownProps) => {
  return {
    me: state.me,
    tenant: state.tenant,
    form: state.form,
  }
}

export default withRouter(injectIntl(connect(mapToProps, actions)(TenantEdit)))
