'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactBroadcast = require('react-broadcast');

var _ThemeProvider = require('./ThemeProvider');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var createColorizor = function createColorizor(theme) {
  return function (color) {
    return theme[color] || color;
  };
};

var Theme = function Theme(_ref, context) {
  var children = _ref.children;

  if (context.broadcasts && context.broadcasts[_ThemeProvider.THEME_CHANNEL]) {
    return _react2.default.createElement(
      _reactBroadcast.Subscriber,
      { channel: _ThemeProvider.THEME_CHANNEL },
      function (theme) {
        return children({
          theme: theme,
          colorize: createColorizor(theme)
        });
      }
    );
  } else {
    return children({
      defaultTheme: _ThemeProvider.defaultTheme,
      colorize: createColorizor(_ThemeProvider.defaultTheme)
    });
  }
};

Theme.propTypes = {
  children: _propTypes2.default.func.isRequired
};

Theme.contextTypes = _reactBroadcast.Subscriber.contextTypes;

exports.default = Theme;