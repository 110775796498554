import PropTypes from 'prop-types'
import React from 'react'
import { css } from 'glamor'
import { View } from '@allthings/react-view'

const styles = {
  box: css({
    margin: 'auto',
    background: '#fff',
    maxWidth: 760,
    width: '100%',
    padding: 40,
    borderRadius: 2,
    wordBreak: 'break-word',
    height: '100%',
  }),
}

const FullPage = ({ children, onLogoClick = noop => noop }) => {
  return (
    <View style={{ height: '100vh' }}>
      <View
        direction="column"
        alignH="start"
        alignV="center"
        wrap="wrap"
        {...styles.box}
      >
        {children}
      </View>
    </View>
  )
}

FullPage.propTypes = {
  onLogoClick: PropTypes.func,
  children: PropTypes.node,
}

export default FullPage
