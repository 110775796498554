'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

var _math = require('./utils/math');

var _ExpandingTextarea = require('../ExpandingTextarea');

var _ExpandingTextarea2 = _interopRequireDefault(_ExpandingTextarea);

var _Text = require('../../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

var _CardButton = require('./CardButton');

var _CardButton2 = _interopRequireDefault(_CardButton);

var _CardFooter = require('./CardFooter');

var _CardFooter2 = _interopRequireDefault(_CardFooter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
  wrapper: (0, _glamor.css)({
    position: 'absolute',
    left: 0,
    boxShadow: '0 0 0 99999px rgba(0,0,0,0.5)',
    width: '100%',
    zIndex: 2,
    background: '#fff'
  })
};

var CardOverlayEditor = function (_React$Component) {
  (0, _inherits3.default)(CardOverlayEditor, _React$Component);

  function CardOverlayEditor(props) {
    (0, _classCallCheck3.default)(this, CardOverlayEditor);

    var _this = (0, _possibleConstructorReturn3.default)(this, (CardOverlayEditor.__proto__ || (0, _getPrototypeOf2.default)(CardOverlayEditor)).call(this, props));

    _this.isOutsideElement = function (_ref, _ref2) {
      var x = _ref.x,
          y = _ref.y;
      var top = _ref2.top,
          bottom = _ref2.bottom,
          left = _ref2.left,
          right = _ref2.right;

      return !(0, _math.between)(y, top, bottom) || !(0, _math.between)(x, left, right);
    };

    _this.handleClick = function (e) {
      var _this$element$getBoun = _this.element.getBoundingClientRect(),
          bottom = _this$element$getBoun.bottom,
          left = _this$element$getBoun.left,
          right = _this$element$getBoun.right,
          top = _this$element$getBoun.top;

      if (_this.isOutsideElement({ x: e.clientX, y: e.clientY }, { bottom: bottom, left: left, right: right, top: top })) {
        e.stopPropagation();
        _this.triggerClose();
        return false;
      }
    };

    _this.setElement = function (element) {
      return _this.element = element;
    };

    _this.triggerClose = function () {
      if (!_this.state.text || _this.state.text && window.confirm(_this.props.confirmText)) {
        _this.props.onRequestClose && _this.props.onRequestClose();
      }
    };

    _this.handleSave = function (e) {
      return _this.props.onSave(_this.state.text);
    };

    _this.handleChange = function (e) {
      var text = e.target.value;
      _this.setState(function () {
        return { text: text };
      });
    };

    _this.state = {
      text: props.initialText
    };
    return _this;
  }

  (0, _createClass3.default)(CardOverlayEditor, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      document.addEventListener('click', this.handleClick, true);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      document.removeEventListener('click', this.handleClick, true);
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({ onRef: this.setElement }, (0, _glamor.css)(styles.wrapper)),
        _react2.default.createElement(
          _View2.default,
          (0, _glamor.css)({ padding: 15 }),
          _react2.default.createElement(_ExpandingTextarea2.default, {
            value: this.state.text,
            onChange: this.handleChange,
            style: { padding: 0, color: '#333' },
            autoFocus: true
          })
        ),
        _react2.default.createElement(
          _CardFooter2.default,
          null,
          _react2.default.createElement(
            _CardButton2.default,
            { onClick: this.triggerClose },
            _react2.default.createElement(
              _Text2.default,
              { size: 'm', color: '#626262' },
              this.props.cancelText
            )
          ),
          _react2.default.createElement(
            _CardButton2.default,
            { onClick: this.handleSave },
            _react2.default.createElement(
              _Text2.default,
              { size: 'm', color: '#626262' },
              this.props.submitText
            )
          )
        )
      );
    }
  }]);
  return CardOverlayEditor;
}(_react2.default.Component);

CardOverlayEditor.propTypes = {
  initialText: _propTypes2.default.string,
  confirmText: _propTypes2.default.string.isRequired,
  cancelText: _propTypes2.default.string.isRequired,
  submitText: _propTypes2.default.string.isRequired,
  onSave: _propTypes2.default.func.isRequired,
  onRequestClose: _propTypes2.default.func,
  children: _propTypes2.default.node
};
exports.default = CardOverlayEditor;