import { messages } from './messages'

export const getTenancyTitle = (formatMessage, tenancy) => {
  const title = []

  title.push(`${tenancy.type} ${tenancy.street}`)
  if (tenancy.floor) {
    title.push(formatMessage(messages.floor, { num: tenancy.floor }))
  }
  if (tenancy.area) {
    title.push(formatMessage(messages.area, { num: tenancy.area }))
  }
  if (tenancy.rooms) {
    title.push(formatMessage(messages.rooms, { num: tenancy.rooms }))
  }

  return title.join(', ')
}

export const getLocalDate = (date, locale) => {
  const options = {
    // weekday: 'long', "narrow", "short", "long"
    year: 'numeric', // "numeric", "2-digit"
    month: 'numeric', // "numeric", "2-digit", "narrow", "short", "long"
    day: 'numeric', // "numeric", "2-digit"
  }

  return new Date(date).toLocaleDateString(locale.replace(/_/, '-'), options)
}
