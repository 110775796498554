'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _glamor = require('glamor');

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
  textarea: (0, _glamor.css)({
    outline: 'none',
    border: 0,
    fontSize: 13,
    resize: 'none',
    flex: 1,
    minHeight: 20,
    maxHeight: '25vh',
    width: '100%',
    paddingLeft: 15
  })

  /**
   * The height of the ExpandingTextarea will expand when the user adds a new line.
   * It will take at maximum 25% of the current viewport. (max-height: 25vh)
   *
   * ```example
   * <ExpandingTextarea
   *   placeholder="Write somthing..."
   *   value=""
   * />
   * ```
   */
};
var ExpandingTextarea = function (_React$Component) {
  (0, _inherits3.default)(ExpandingTextarea, _React$Component);

  function ExpandingTextarea() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, ExpandingTextarea);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = ExpandingTextarea.__proto__ || (0, _getPrototypeOf2.default)(ExpandingTextarea)).call.apply(_ref, [this].concat(args))), _this), _this.setTextarea = function (textarea) {
      _this.textarea = textarea;
      _this.props.onTextarea && _this.props.onTextarea(textarea);
    }, _this.handleChange = function (e) {
      _this.props.onChange && _this.props.onChange(e);
      _this.adjustTextareaHeight();
    }, _this.adjustTextareaHeight = function () {
      var _this2 = _this,
          textarea = _this2.textarea;

      if (textarea) {
        var onHeightChange = _this.props.onHeightChange;


        textarea.style.height = 0;
        textarea.style.height = textarea.scrollHeight + 'px';

        if (onHeightChange) {
          var actualHeight = Math.min(textarea.scrollHeight, textarea.offsetHeight);
          onHeightChange(actualHeight);
        }
      }
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(ExpandingTextarea, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.adjustTextareaHeight();
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      if (nextProps.autoFocus === true && this.props.autoFocus === false && this.textarea) {
        this.textarea.focus();
        this.props.onFocus && this.props.onFocus();
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (this.props.value !== prevProps.value) {
        this.adjustTextareaHeight();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      // eslint-disable-next-line
      var _props = this.props,
          placeholder = _props.placeholder,
          containerStyle = _props.containerStyle,
          onTextarea = _props.onTextarea,
          restProps = (0, _objectWithoutProperties3.default)(_props, ['placeholder', 'containerStyle', 'onTextarea']);


      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({}, (0, _glamor.css)(containerStyle), {
          alignV: 'center',
          flex: 'flex',
          direction: 'row'
        }),
        _react2.default.createElement('textarea', (0, _extends3.default)({}, restProps, {
          cols: '1',
          rows: '1',
          placeholder: placeholder,
          onChange: this.handleChange,
          onKeyDown: this.handleKeyDown,
          ref: this.setTextarea
        }, styles.textarea))
      );
    }
  }]);
  return ExpandingTextarea;
}(_react2.default.Component);

ExpandingTextarea.propTypes = {
  autoFocus: _propTypes2.default.bool,
  placeholder: _propTypes2.default.string,
  onEnter: _propTypes2.default.func,
  onHeightChange: _propTypes2.default.func,
  containerStyle: _propTypes2.default.object,
  onChange: _propTypes2.default.func,
  value: _propTypes2.default.string,
  onFocus: _propTypes2.default.func,
  onTextarea: _propTypes2.default.func
};
ExpandingTextarea.defaultProps = {
  autoFocus: false
};
exports.default = ExpandingTextarea;