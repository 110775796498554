'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
  container: (0, _glamor.css)({
    maxWidth: 250,
    '> *': {
      margin: '10px 0'
    }
  }),
  editor: (0, _glamor.css)({
    boxShadow: '0 0 14px 0 rgba(0,0,0,0.05)',
    alignSelf: 'center',
    borderRadius: '50%',
    overflow: 'hidden',
    height: 224,
    width: 224,
    margin: '21px 0'
  }),
  slider: (0, _glamor.css)({
    WebkitAppearance: 'none',
    width: '100%',
    margin: '7px 0',
    ':focus': {
      outline: 'none'
    },
    '::-webkit-slider-runnable-track': {
      width: '100%',
      height: 2,
      cursor: 'pointer',
      background: '#4a5256',
      borderRadius: 4,
      border: '0px solid #010101'
    },
    '::-webkit-slider-thumb': {
      border: '0px solid #000000',
      height: 16,
      width: 16,
      borderRadius: '50%',
      background: '#4a5256',
      cursor: 'pointer',
      WebkitAppearance: 'none',
      marginTop: '-7px'
    },
    ':focus::-webkit-slider-runnable-track': {
      background: '#646f74'
    },
    '::-moz-range-track': {
      width: '100%',
      height: 2,
      cursor: 'pointer',
      background: '#4a5256',
      borderRadius: 4,
      border: '0px solid #010101'
    },
    '::-moz-range-thumb': {
      border: '0px solid #000000',
      height: 16,
      width: 16,
      borderRadius: '50%',
      background: '#4a5256',
      cursor: 'pointer'
    },
    '::-ms-track': {
      width: '100%',
      height: 2,
      cursor: 'pointer',
      background: 'transparent',
      borderColor: 'transparent',
      color: 'transparent'
    },
    '::-ms-fill-lower': {
      background: '#303538',
      border: '0px solid #010101',
      borderRadius: 4
    },
    '::-ms-fill-upper': {
      background: '#4a5256',
      border: '0px solid #010101',
      borderRadius: 4
    },
    '::-ms-thumb': {
      border: '0px solid #000000',
      height: 2,
      width: 16,
      borderRadius: '50%',
      background: '#4a5256',
      cursor: 'pointer'
    },
    ':focus::-ms-fill-lower': {
      background: '#4a5256'
    },
    ':focus::-ms-fill-upper': {
      background: '#646f74'
    }
  })

  /**
   * Slider give the user a way to select from a limited range of numbers.
   *
   * ```example
   * <Slider
   *   min={20}
   *   max={200}
   *   step={10}
   * />
   * ```
   */
};
var Slider = function (_React$Component) {
  (0, _inherits3.default)(Slider, _React$Component);

  function Slider() {
    (0, _classCallCheck3.default)(this, Slider);
    return (0, _possibleConstructorReturn3.default)(this, (Slider.__proto__ || (0, _getPrototypeOf2.default)(Slider)).apply(this, arguments));
  }

  (0, _createClass3.default)(Slider, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          min = _props.min,
          max = _props.max,
          step = _props.step,
          value = _props.value,
          onChange = _props.onChange,
          props = (0, _objectWithoutProperties3.default)(_props, ['min', 'max', 'step', 'value', 'onChange']);

      return _react2.default.createElement(
        _View2.default,
        props,
        _react2.default.createElement('input', (0, _extends3.default)({
          max: max,
          min: min,
          onChange: onChange,
          step: step,
          type: 'range',
          value: value
        }, styles.slider))
      );
    }
  }]);
  return Slider;
}(_react2.default.Component);

Slider.propTypes = {
  /** Minimum selectable value **/
  min: _propTypes2.default.number.isRequired,
  /** Maximum selectable value **/
  max: _propTypes2.default.number.isRequired,
  /** Step interval **/
  step: _propTypes2.default.number,
  /** Current value to show **/
  value: _propTypes2.default.number,
  /** Callback when the users changes the value **/
  onChange: _propTypes2.default.func
};
Slider.defaultProps = {
  step: 0.1
};
exports.default = Slider;