'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

var _colors = require('@allthings/colors');

var _SwitchListItem = require('./SwitchListItem');

var _SwitchListItem2 = _interopRequireDefault(_SwitchListItem);

var _SwitchListSpinner = require('./SwitchListSpinner');

var _SwitchListSpinner2 = _interopRequireDefault(_SwitchListSpinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var switchListRadius = '2px';

var styles = {
  container: (0, _glamor.css)({
    '> *:last-child': {
      borderBottom: 'none'
    },
    '> *': {
      borderBottom: '1px solid ' + _colors.ColorPalette.text.gray
    },
    overflow: 'hidden',
    border: '1px solid ' + _colors.ColorPalette.text.gray,
    borderRadius: '' + switchListRadius,
    ':hover': { cursor: 'pointer' }
  }),
  switchListItem: (0, _glamor.css)({ position: 'relative' }),
  itemInnerContainer: (0, _glamor.css)({
    backgroundColor: _colors.ColorPalette.text.gray,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%'
  })

  /**
   * Let's you choose between a limited set of items.
   *
   * **Note:** This is not meant to used for navigation
   *
   * ```example
   * <SwitchList
   *   options={{
   *     a: 'Freiburg',
   *     b: 'Berlin',
   *     c: 'München',
   *   }}
   *   initialActive="b"
   *   showSpinner={false}
   * />
   * ```
   */
};
var SwitchList = function (_React$Component) {
  (0, _inherits3.default)(SwitchList, _React$Component);

  function SwitchList() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, SwitchList);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = SwitchList.__proto__ || (0, _getPrototypeOf2.default)(SwitchList)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      active: _this.props.initialActive
    }, _this.handleClick = function (optionKey) {
      if (!_this.props.disabled) {
        _this.props.onChange(optionKey);
        _this.setState({ active: optionKey });
      }
    }, _this.renderItems = function () {
      return (0, _keys2.default)(_this.props.options).map(function (optionKey) {
        return _react2.default.createElement(
          _SwitchListItem2.default,
          (0, _extends3.default)({
            key: optionKey,
            optionKey: optionKey,
            value: _this.props.options[optionKey],
            isActive: _this.state.active === optionKey,
            onClick: _this.handleClick
          }, styles.switchListItem),
          _this.props.showSpinner && _this.state.active === optionKey && _react2.default.createElement(
            _View2.default,
            (0, _extends3.default)({
              key: optionKey,
              direction: 'row',
              alignV: 'center'
            }, styles.itemInnerContainer),
            _react2.default.createElement(_SwitchListSpinner2.default, { width: 23, fill: _colors.ColorPalette.text.gray })
          )
        );
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(SwitchList, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          disabled = _props.disabled,
          onChange = _props.onChange,
          options = _props.options,
          initialActive = _props.initialActive,
          showSpinner = _props.showSpinner,
          props = (0, _objectWithoutProperties3.default)(_props, ['disabled', 'onChange', 'options', 'initialActive', 'showSpinner']);

      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({}, styles.container, props),
        this.renderItems()
      );
    }
  }]);
  return SwitchList;
}(_react2.default.Component);

SwitchList.propTypes = {
  disabled: _propTypes2.default.bool,
  onChange: _propTypes2.default.func,
  options: _propTypes2.default.object,
  initialActive: _propTypes2.default.string,
  showSpinner: _propTypes2.default.bool
};
exports.default = SwitchList;