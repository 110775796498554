'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _CardList = require('./CardList');

var _CardList2 = _interopRequireDefault(_CardList);

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _GroupTitle = require('../../atoms/GroupTitle');

var _GroupTitle2 = _interopRequireDefault(_GroupTitle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GroupedCardList = function GroupedCardList(_ref) {
  var title = _ref.title,
      children = _ref.children,
      rest = (0, _objectWithoutProperties3.default)(_ref, ['title', 'children']);

  return _react2.default.createElement(
    _View2.default,
    rest,
    _react2.default.createElement(
      _GroupTitle2.default,
      null,
      title
    ),
    _react2.default.createElement(
      _CardList2.default,
      null,
      children
    )
  );
};

GroupedCardList.propTypes = {
  /** See ListItem and View */
  children: _propTypes2.default.node,
  e2e: _propTypes2.default.string,
  /** The title for the CardList */
  title: _propTypes2.default.string
};

exports.default = GroupedCardList;