import React from 'react'
import PropTypes from 'prop-types'
import { View, TitleBar, SquareIconButton, Text } from '@allthings/elements'
import { Link } from 'react-router-dom'

class HeaderBar extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    lBtnIcon: PropTypes.string,
    lBtnTarget: PropTypes.string,
    rBtnIcon: PropTypes.string,
    rBtnTarget: PropTypes.string,
  }

  static defaultProps = {
    lBtnTarget: '/',
  }

  render() {
    const { lBtnIcon, lBtnTarget, rBtnIcon, rBtnTarget } = this.props
    return (
      <TitleBar alignH="space-between">
        <View direction="row" alignV="center">
          {lBtnIcon && (
            <Link to={lBtnTarget}>
              <SquareIconButton icon={lBtnIcon} iconColor="white" />
            </Link>
          )}
          <Text color="white" strong style={{ marginLeft: lBtnIcon ? 0 : 15 }}>
            {this.props.label}
          </Text>
        </View>
        {rBtnIcon &&
          rBtnTarget && (
            <Link to={rBtnTarget}>
              <SquareIconButton icon={rBtnIcon} iconColor="white" />
            </Link>
          )}
      </TitleBar>
    )
  }
}
export default HeaderBar
