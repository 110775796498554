'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var style = (0, _glamor.css)({
  width: '100%',
  background: 'white',
  boxShadow: '2px 2px 2px rgba(230, 230, 230, 0.5)'
});

/**
 * Cards can be used to group related content
 *
 * ```example
 * <Card>
 *  <CardContent>
 *    <Text size="xl" strong>
 *      Cards
 *    </Text>
 *    <Text>
 *      Cards are the basic elements to fit content in. They can may
 *      contain any kind of content.
 *    </Text>
 *  </CardContent>
 * </Card>
 * ```
 */
var Card = function Card(_ref) {
  var children = _ref.children,
      containerStyle = _ref.containerStyle,
      props = (0, _objectWithoutProperties3.default)(_ref, ['children', 'containerStyle']);

  return _react2.default.createElement(
    _View2.default,
    (0, _extends3.default)({}, (0, _glamor.css)(style, containerStyle), props),
    children
  );
};

Card.propTypes = {
  children: _propTypes2.default.node,
  containerStyle: _propTypes2.default.object
};

exports.default = Card;