import { PV_API_ROOT, PV_AUTH } from './api-config'
import {
  account as accountMock,
  contacts as contactsMock,
  tenancy as tenancyMock,
  tenant as mainTenantMock,
  tenants as subTenantsMock,
} from './mock'
const env = NODE_ENV || 'production'

function getPriveraWebServiceHeaders(locale, options) {
  const headers = (options && options.headers) || new Headers()
  headers.append('Accept-Language', locale.replace('_', '-'))
  headers.append('Content-Type', 'application/json; charset=UTF-8')
  headers.append('Authorization', `Basic ${PV_AUTH}`)
  return headers
}

export const callPriveraApi = props => {
  const { method, path, locale, data } = props
  const req = {
    method: method || 'GET',
    headers: getPriveraWebServiceHeaders(locale),
    credentials: 'include',
  }
  const handleResponse = async res => {
    if (!res.ok || res.status >= 400) {
      let message
      if (res.status === 404) {
        message = res.statusText || 'resource not found'
      }
      if (res.status === 400) {
        message = await res.json()
      }
      return { status: 'error', message }
    } else {
      const data = await res.json()
      if (!data.id && !data.tenant_id && !data.length) {
        const message = 'no data found'
        return { status: 'error', message }
      } else {
        return { status: 'success', data }
      }
    }
  }
  if (data) {
    req.body = JSON.stringify(data)
  }

  if (env === 'production' || env === 'staging') {
    return fetch(`${PV_API_ROOT}/${path}`, req).then(handleResponse)
  } else {
    return fetch(`${PV_API_ROOT}/${path}`, req).then(handleResponse)

    /** Mock Data
    if (method === 'GET') {
      let data
      if (path.match(/tenants\/(.*)\/account/)) {
        data = accountMock
      } else if (path.match(/tenants\/(.*)/)) {
        data = mainTenantMock
      } else if (path.match(/tenancies\/(.*)\/tenants/)) {
        data = subTenantsMock
      } else if (path.match(/tenancies\/(.*)\/contacts/)) {
        data = contactsMock
      } else if (path.match(/tenancies\/(.*)/)) {
        data = tenancyMock
      }

      return { status: 'success', data }
    }
     */
  }
}
