'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendInfo = exports.sendWarning = exports.sendSuccess = undefined;

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _mitt = require('mitt');

var _mitt2 = _interopRequireDefault(_mitt);

var _NotificationBubble = require('../molecules/NotificationBubble');

var _NotificationBubble2 = _interopRequireDefault(_NotificationBubble);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Message = require('./Message');

var _Message2 = _interopRequireDefault(_Message);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var emitter = (0, _mitt2.default)();

var send = function send(message, type) {
  emitter.emit(type, message);
};

var sendSuccess = exports.sendSuccess = function sendSuccess(message) {
  return send(message, 'success');
};
var sendWarning = exports.sendWarning = function sendWarning(message) {
  return send(message, 'warning');
};
var sendInfo = exports.sendInfo = function sendInfo(message) {
  return send(message, 'info');
};

var NotificationBubbleManager = function (_React$Component) {
  (0, _inherits3.default)(NotificationBubbleManager, _React$Component);

  function NotificationBubbleManager() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, NotificationBubbleManager);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = NotificationBubbleManager.__proto__ || (0, _getPrototypeOf2.default)(NotificationBubbleManager)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      messages: []
    }, _this.handleEvent = function (type, message) {
      return _this.setState(function (_ref2) {
        var messages = _ref2.messages;
        return {
          messages: [].concat((0, _toConsumableArray3.default)(messages), [new _Message2.default(type, message)])
        };
      });
    }, _this.handleTimeout = function () {
      return _this.setState(function (_ref3) {
        var messages = _ref3.messages;
        return { messages: messages.slice(1) };
      });
    }, _this.renderBubble = function (message) {
      return _this.props.renderBubble ? _this.props.renderBubble({
        key: message.id,
        onTimeout: _this.handleTimeout,
        children: message.text
      }) : _react2.default.createElement(
        _NotificationBubble2.default,
        { key: message.id, onTimeout: _this.handleTimeout },
        message.text
      );
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(NotificationBubbleManager, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      emitter.on('*', this.handleEvent);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      emitter.off('*', this.handleEvent);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          renderBubble = _props.renderBubble,
          props = (0, _objectWithoutProperties3.default)(_props, ['children', 'renderBubble']);

      var message = this.state.messages[0];

      return _react2.default.createElement(
        _View2.default,
        props,
        message && this.renderBubble(message),
        children
      );
    }
  }]);
  return NotificationBubbleManager;
}(_react2.default.Component);

NotificationBubbleManager.propTypes = {
  children: _propTypes2.default.node,
  renderBubble: _propTypes2.default.func
};
exports.default = NotificationBubbleManager;