import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  contactPersonsHeader: {
    // Ansprechpartner
    id: 'contacts.header-label',
    description: 'The label that is shown at the contact-persons-view',
    defaultMessage: 'Contact persons',
  },
  tenancyHeader: {
    // Mietvertrag
    id: 'tenancy.header-label',
    description: 'The label that is shown in the header at the tenancy-view',
    defaultMessage: 'Contract',
  },
  tenantHeader: {
    // Persönliche Daten
    id: 'tenant.header-label',
    description:
      'The label that is shown in the header at the tenant-detail-view',
    defaultMessage: 'Personal data',
  },
  tenantsHeader: {
    // Mitmieter
    id: 'tenants.header-label',
    description: 'The label that is shown in the header at the tenants-view',
    defaultMessage: 'Tenants',
  },
  tenantAccountHeader: {
    // Mieterkonto
    id: 'account.header-label',
    description: 'The label that is shown in the header at the account-view',
    defaultMessage: 'Tenant account',
  },
  tenantEditHeader: {
    // Editieren sie ihre persönlichen Daten
    id: 'tenant-edit.header-label',
    description:
      'The label that is shown in the header at the tenant-edit-view',
    defaultMessage: 'Edit your Personal Data',
  },
  contract_begin: {
    // Mietbeginn
    id: 'tenancy.contract_begin-label',
    description: 'The label that is shown for the contract-begin section',
    defaultMessage: 'Contract begin',
  },
  notice_type: {
    // Kündigungstermin
    id: 'tenancy.notice_type-label',
    description: 'The label that is shown for the notice-type section',
    defaultMessage: 'Notice type',
  },
  notice_period: {
    // Kündigungsfrist
    id: 'tenancy.notice_period-label',
    description: 'The label that is shown for the notice_period section',
    defaultMessage: 'Notice period',
  },
  ancillary_cost_period: {
    // NK-Periode
    id: 'tenancy.ancillary_cost_period-label',
    description:
      'The label that is shown for the ancillary_cost_period section',
    defaultMessage: 'Ancillary cost period',
  },
  billing_model: {
    // Zahlungsmodus
    id: 'tenancy.billing_model-label',
    description: 'The label that is shown for the billing_model section',
    defaultMessage: 'Billing model',
  },
  deposit: {
    // Depotbetrag
    id: 'tenancy.deposit-label',
    description: 'The label that is shown for the deposit section',
    defaultMessage: 'Deposit',
  },
  deposit_type: {
    // Depotart
    id: 'tenancy.deposit_type-label',
    description: 'The label that is shown for the deposit_type section',
    defaultMessage: 'Deposit type',
  },
  deposit_adress: {
    // Depotadresse
    id: 'tenancy.deposit_adress-label',
    description: 'The label that is shown for the home deposit_adress section',
    defaultMessage: 'Deposit adress',
  },
  inspection_protocol: {
    // Wohnungsabnahmeprotokoll
    id: 'tenancy.inspection_protocol-label',
    description: 'The label that is shown for the inspection_protocol section',
    defaultMessage: 'Inspection protocol',
  },
  rent_total: {
    // Bruttotmietzins
    id: 'tenancy.rent_total-label',
    description: 'The label that is shown for the rent_total section',
    defaultMessage: 'Rent total',
  },
  rent_composition: {
    // Mietzinszusammensetzung
    id: 'tenancy.rent_composition-label',
    description: 'The label that is shown for the rent_composition section',
    defaultMessage: 'Rent composition',
  },
  download: {
    // Download
    id: 'tenancy.download-label',
    description: 'The label that is shown for the download section',
    defaultMessage: 'Download',
  },
  floor: {
    // Stockwerk
    id: 'tenancy.floors-text',
    description: 'The level in the tenancy description',
    defaultMessage: '{num, plural, =1{#st} =2{#nd} =3{#rd} other{#th}} floor',
  },
  area: {
    // Fläche
    id: 'tenancy.area-text',
    description: 'The area in the tenancy description',
    defaultMessage: '{num}',
  },
  rooms: {
    // Zimmer
    id: 'tenancy.rooms-text',
    description: 'The number of rooms in the tenancy description',
    defaultMessage: '{num, plural, one {# room} other {# rooms}}',
  },
  noticeHeader: {
    id: 'tenancy.notice',
    description: 'The label that is shown in the header at the notice group',
    defaultMessage: 'Notice',
  },
  canceling: {
    id: 'tenancy.canceling',
    description: 'The label that is shown for canceling a rental agreement',
    defaultMessage: 'Canceling a rental agreement (e-Notice)',
  },
  canceling_template: {
    id: 'tenancy.canceling_template',
    description: 'The label that is shown for downloading a termination letter',
    defaultMessage: 'Letter of termination',
  },

  title: {
    id: 'tenant.title-label',
    description: 'The label that is shown for the title',
    defaultMessage: 'Title',
  },
  additional_title: {
    id: 'tenant.additional-title-label',
    description: 'The label that is shown for the additional title',
    defaultMessage: 'Additional title',
  },
  first_name: {
    id: 'tenant.first-name-label',
    description: 'The label that is shown for the first name',
    defaultMessage: 'First name',
  },
  last_name: {
    id: 'tenant.last-name-label',
    description: 'The label that is shown for the last name',
    defaultMessage: 'Last name',
  },
  birthday: {
    id: 'tenant.birthday-label',
    description: 'The label that is shown for the birthday',
    defaultMessage: 'birthday',
  },
  email: {
    id: 'tenant.email-label',
    description: 'The label that is shown for the email',
    defaultMessage: 'email',
  },
  phone_mobile: {
    id: 'tenant.phone-mobile',
    description: 'The label that is shown for the mobile phone',
    defaultMessage: 'Phone mobile (e.g. +41 00 000 00 00)',
  },
  phone_home: {
    id: 'tenant.phone-home',
    description: 'The label that is shown for the home phone',
    defaultMessage: 'Phone home (e.g. +41 00 000 00 00)',
  },
  phone_business: {
    id: 'tenant.phone-business',
    description: 'The label that is shown for the business phone',
    defaultMessage: 'Phone business (e.g. +41 00 000 00 00)',
  },
  marital_status: {
    id: 'tenant.marital-status-label',
    description: 'The label that is shown for the marital-status',
    defaultMessage: 'Marital status',
  },
  iban: {
    id: 'tenant.iban-label',
    description: 'The label that is shown for the iban',
    defaultMessage: 'iban',
  },
  balance_total: {
    // Saldo / Guthaben
    id: 'account.balance_total-label',
    description: 'The label that is shown for the total balance section',
    defaultMessage: 'Balance/Credit',
  },
  due_date: {
    // Fälligkeitsdatum
    id: 'account.due_date-label',
    description: 'The label that is shown for the due_date section',
    defaultMessage: 'Due date',
  },
  balance: {
    // Saldo
    id: 'account.balance-label',
    description: 'The label that is shown for the balance section',
    defaultMessage: 'Balance',
  },
  amount_claimed: {
    // Forderung total
    id: 'account.amount_claimed-label',
    description: 'The label that is shown for the amount_claimed section',
    defaultMessage: 'Amount claimed',
  },
  amount_paid: {
    // Zahlung total
    id: 'account.amount_paid-label',
    description: 'The label that is shown for the amount_paid section',
    defaultMessage: 'Amount paid',
  },
  claim_type: {
    // Forderungstyp
    id: 'account.claim_type-label',
    description: 'The label that is shown for the claim type section',
    defaultMessage: 'Claim type',
  },
  salutation: {
    // Anrede
    id: 'form.salutation-label',
    description: 'The label that is shown at the salutation-field',
    defaultMessage: 'Salutation',
  },
  formSucceeded: {
    id: 'form.success-message',
    description: 'The label that is shown when the form was successfully sent.',
    defaultMessage: 'Your data were updated successfully. Thank you!',
  },
  noContactPersonsDefined: {
    id: 'contacts.not-defined-text',
    description: 'Text that is shown if no contact persons are defined yet',
    defaultMessage: 'No contact persons defined yet.',
  },
  badInput: {
    id: 'form.validity.bad-input',
    description: 'Bad input error message.',
    defaultMessage: 'Bad input.',
  },
  customError: {
    id: 'form.validity.custom-error',
    description: 'Custom error message.',
    defaultMessage: 'Custom error.',
  },
  patternMismatch: {
    id: 'form.validity.pattern-mismatch',
    description: 'Pattern mismatch error message.',
    defaultMessage: 'Pattern mismatch.',
  },
  valueMissing: {
    id: 'form.validity.value-missing',
    description: 'Validity error message when the value is missing.',
    defaultMessage: 'Please fill out this field.',
  },
  phoneValidityMessage: {
    id: 'form.phone-validity-message',
    description:
      'The validity-message that is shown if the phone has an incorrect format',
    defaultMessage: 'Invalid phone number format (+41 00 000 00 00)',
  },
  ibanValidityMessage: {
    id: 'form.iban-validity-message',
    description:
      'The validity-message that is shown if the iban has an incorrect format',
    defaultMessage: 'Invalid iban format (CH00 0000 0000 0000 0000 0)',
  },
  salutationMr: {
    id: 'form.salutation-mr',
    description: 'The salutation for Mr',
    defaultMessage: 'Mr',
  },
  salutationMrs: {
    id: 'form.salutation-mrs',
    description: 'The salutation for Mrs',
    defaultMessage: 'Mrs',
  },
  noAccessTitle: {
    id: 'no-access.title',
    description: 'Inform the user, that he/she has no access to the microapp.',
    defaultMessage:
      'We are sorry you cannot access this mircoapp at the moment.',
  },
  noAccessText: {
    id: 'no-access.text',
    description: 'Hint about contacting the admin.',
    defaultMessage: 'Please contact the admin about this issue.',
  },
  noContentTitle: {
    id: 'no-content.title',
    description: 'Inform the user, that there are no entries at the moment.',
    defaultMessage: 'There are no entries right now.',
  },
  noContentText: {
    id: 'no-content.text',
    description: 'Inform the user, that he/she can contact the administrator.',
    defaultMessage: 'Questions?<br />Please contact your administrator.',
  },
  buttonSendRequest: {
    id: 'button.send-request',
    description: 'The label for the send request button',
    defaultMessage: 'Send request',
  },
})
