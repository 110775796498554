import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ImageColumn from '../components/ImageColumn'
import FullPage from '../components/FullPage'
import { withRouter } from 'react-router-dom'
import { Button, Spacer, Text } from '@allthings/elements'
import { width } from '../helper/viewport'

import { PV_ASSETS_URL } from '../helper/api-config'

class NoAccessPage extends React.Component {
  static propTypes = {
    btnTarget: PropTypes.string,
    history: PropTypes.object,
    onLogoClicked: PropTypes.func,
    userAgent: PropTypes.string,
  }

  static defaultProps = {
    onLogoClicked: (_) => _,
    onButtonClicked: (_) => _,
  }

  render() {
    return (
      <FullPage onLogoClick={this.props.onLogoClicked}>
        <Text size="xl" align="center">
          <FormattedMessage
            id="no-access.title"
            description="Inform the user, that he/she has no access to the microapp."
            defaultMessage="We're sorry you cannot access this mircoapp at the moment."
          />
        </Text>
        <Spacer height={40} />
        <ImageColumn
          source={`${PV_ASSETS_URL}/img/error_${
            width() === 'sm' ? 'mobile' : 'desktop'
          }.svg`}
          width="100%"
          height="100%"
        />
        <Spacer height={40} />
        <Text align="center">
          <FormattedMessage
            id="no-access.text"
            description="Hint about contacting the admin."
            defaultMessage="Please contact the admin about this issue."
          />
        </Text>
        <Spacer height={20} />
      </FullPage>
    )
  }
}

export default withRouter(NoAccessPage)
