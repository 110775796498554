"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require("babel-runtime/helpers/classCallCheck");

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Message = function Message(type, text) {
  (0, _classCallCheck3.default)(this, Message);

  this.date = new Date();
  this.type = type;
  this.text = text;
  this.id = Message.messageCount++;
};

Message.messageCount = 0;
exports.default = Message;