import React from 'react'
import 'babel-polyfill'
import { createBrowserHistory } from 'history'
import { messages } from './helper/messages'

import { Provider } from 'redux-zero/react'

import { addLocaleData } from 'react-intl'
import de from 'react-intl/locale-data/de'
import en from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'
import it from 'react-intl/locale-data/it'

import store from './store'
import StoreIntlProvider from './containers/StoreIntlProvider'
import App from './App'

import { ColorPalette } from '@allthings/colors'
import { ResourceProvider, ThemeProvider } from '@allthings/elements'

// the microapp has a custom theme which is based on the
// default theme
const microappTheme = (color) => {
  const baseTheme = {
    primary: ColorPalette.primary.blue,
    text: ColorPalette.text.primary,
    secondaryText: ColorPalette.text.primary,
    titleColor: ColorPalette.grey,
    contrast: ColorPalette.white,
    disabled: ColorPalette.grey,
    background: ColorPalette.background.bright,
    textOnBackground: ColorPalette.white,
  }

  if (color !== '') {
    baseTheme['primary'] = `#${color}`
  }

  return baseTheme
}

// https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
const STATES = ['badInput', 'customError', 'patternMismatch', 'valueMissing']

class Root extends React.Component {
  constructor(props) {
    console.log('Props:', props)
    addLocaleData([...de, ...en, ...fr, ...it])

    super(props)
    this.validity = STATES.reduce(
      (acc, prop) => ({
        ...acc,
        [prop]: messages[prop],
      }),
      {}
    )
  }

  getLanguage(language) {
    switch (language) {
      case 0:
        return 'de'
      case 1:
        return 'fr'
      case 2:
        return 'it'
      case 3:
        return 'en'
      default:
        return 'de'
    }
  }

  render() {
    const { user } = this.props

    if (!global.Intl) {
      require.ensure(
        [
          'intl',
          'intl/locale-data/jsonp/en.js',
          'intl/locale-data/jsonp/de.js',
        ],
        function (require) {
          require('intl')
          require('intl/locale-data/jsonp/de.js')
          require('intl/locale-data/jsonp/en.js')
          require('intl/locale-data/jsonp/fr.js')
          require('intl/locale-data/jsonp/it.js')
        },
        'Locales' // Give it a name for bundle analyzer.
      )
    }

    return (
      <Provider store={store}>
        <StoreIntlProvider locale={this.getLanguage(user.language)}>
          <ThemeProvider theme={microappTheme('68947C')}>
            <ResourceProvider>
              <App tenantOID={user.tenantOid} />
            </ResourceProvider>
          </ThemeProvider>
        </StoreIntlProvider>
      </Provider>
    )
  }
}

export default Root
