'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

var _Theme = require('../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var circle = function circle(backgroundColor, outline, outlineColor, fill, radius) {
  return (0, _glamor.css)({
    border: outline && '1px solid ' + (outlineColor || backgroundColor),
    backgroundColor: fill && backgroundColor,
    borderRadius: '50%',
    height: radius,
    width: radius,
    transition: 'background-color 120ms linear'
  });
};

/**
 * Simple circle element that is used to build other things.
 *
 * Check out ProfileImage to see it in action.
 *
 * ```example
 * <ThemeProvider>
 *   <ResourceProvider>
 *     <View direction="row" alignV="center">
 *       <Circle outline fill={false} outlineColor="lightGrey">
 *         <Icon size="s" name="remove-filled" color="lightGrey" />
 *       </Circle>
 *       <Circle color="primary" />
 *       <Circle color="#bada55" radius={20} />
 *       <Circle color="gray" radius={10} />
 *     </View>
 *   </ResourceProvider>
 * </ThemeProvider>
 * ```
**/
var Circle = function Circle(_ref) {
  var _ref$fill = _ref.fill,
      fill = _ref$fill === undefined ? true : _ref$fill,
      _ref$color = _ref.color,
      color = _ref$color === undefined ? 'primary' : _ref$color,
      outline = _ref.outline,
      _ref$outlineColor = _ref.outlineColor,
      outlineColor = _ref$outlineColor === undefined ? 'primary' : _ref$outlineColor,
      _ref$radius = _ref.radius,
      radius = _ref$radius === undefined ? 40 : _ref$radius,
      children = _ref.children,
      props = (0, _objectWithoutProperties3.default)(_ref, ['fill', 'color', 'outline', 'outlineColor', 'radius', 'children']);
  return _react2.default.createElement(
    _Theme2.default,
    null,
    function (_ref2) {
      var colorize = _ref2.colorize;
      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({}, circle(colorize(color), outline, colorize(outlineColor), fill, radius), {
          alignV: 'center',
          alignH: 'center',
          direction: 'column'
        }, props),
        children
      );
    }
  );
};

Circle.propTypes = {
  /** Content of the Circle will be always centered */
  children: _propTypes2.default.node,
  /** Color of the Circle. Allows theme names (like 'primary') or hex colors  */
  color: _propTypes2.default.string,
  /** Radius of Circle, defaults to 40 */
  radius: _propTypes2.default.number,
  /** If true it will only render the outline */
  outline: _propTypes2.default.bool,
  /** If passed outline will use as outline color instead of color */
  outlineColor: _propTypes2.default.string,
  /** If true it will fill the circle */
  fill: _propTypes2.default.bool
};

exports.default = Circle;