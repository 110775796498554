"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require("babel-runtime/core-js/object/get-prototype-of");

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require("babel-runtime/helpers/classCallCheck");

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require("babel-runtime/helpers/createClass");

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require("babel-runtime/helpers/possibleConstructorReturn");

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require("babel-runtime/helpers/inherits");

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * The spinner is usefull to indicate a loading state
 *
 * ```example
 * <Spinner />
 * ```
 */
var Spinner = function (_React$Component) {
  (0, _inherits3.default)(Spinner, _React$Component);

  function Spinner() {
    (0, _classCallCheck3.default)(this, Spinner);
    return (0, _possibleConstructorReturn3.default)(this, (Spinner.__proto__ || (0, _getPrototypeOf2.default)(Spinner)).apply(this, arguments));
  }

  (0, _createClass3.default)(Spinner, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "svg",
        {
          width: "30px",
          height: "30px",
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 100 100",
          preserveAspectRatio: "xMidYMid"
        },
        _react2.default.createElement("rect", { x: "0", y: "0", width: "100", height: "100", fill: "none" }),
        _react2.default.createElement("circle", {
          cx: "50",
          cy: "50",
          r: "40",
          stroke: "#ffffff",
          fill: "none",
          strokeWidth: "10",
          strokeLinecap: "round"
        }),
        _react2.default.createElement(
          "circle",
          {
            cx: "50",
            cy: "50",
            r: "40",
            stroke: "#369980",
            fill: "none",
            strokeWidth: "8",
            strokeLinecap: "round"
          },
          _react2.default.createElement("animate", {
            attributeName: "stroke-dashoffset",
            dur: "2s",
            repeatCount: "indefinite",
            from: "0",
            to: "502"
          }),
          _react2.default.createElement("animate", {
            attributeName: "stroke-dasharray",
            dur: "2s",
            repeatCount: "indefinite",
            values: "125.5 125.5;1 250;125.5 125.5"
          })
        )
      );
    }
  }]);
  return Spinner;
}(_react2.default.Component);

exports.default = Spinner;