import PropTypes from 'prop-types'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'redux-zero/react'

import actions from '../actions'

/* eslint-disable camelcase */
import de_DE from '../../translations/de_DE.json'
import en_US from '../../translations/en_US.json'
import fr_FR from '../../translations/fr_FR.json'
import it_IT from '../../translations/it_IT.json'

const getMessages = (locale) => {
  return {
    de: { ...de_DE },
    en: { ...en_US },
    fr: { ...fr_FR },
    it: { ...it_IT },
  }[locale]
}
/* eslint-enable camelcase */

class StoreIntlProvider extends React.Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    children: PropTypes.node,
    setLocale: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const countryCode = this.props.locale.split('_')[0]
    this.props.setLocale(countryCode)
  }

  render() {
    const countryCode = this.props.locale.split('_')[0]

    return (
      <IntlProvider locale={countryCode} messages={getMessages(countryCode)}>
        {this.props.children}
      </IntlProvider>
    )
  }
}

const mapToProps = (state, ownProps) => {
  return {
    locale: state.locale,
  }
}

export default connect(mapToProps, actions)(StoreIntlProvider)
