'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

var _Theme = require('../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

var _Icon = require('../atoms/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Text = require('../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

var _reactMotion = require('react-motion');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
  bubble: (0, _glamor.css)({
    zIndex: 200,
    position: 'fixed',
    bottom: 50,
    left: '50%',
    transform: 'translate(-50%, 0)',
    margin: '0 auto',
    width: 260,
    height: 68,
    borderRadius: 45,
    boxShadow: '2px 2px 14px 1px rgba(35,35,35,0.2)'
  }),
  text: (0, _glamor.css)({
    width: 200,
    marginLeft: 20
  })

  /**
   * NotificationBubbles are used to inform users about important
   * information, when an action has failed or succeeded. They're a most
   * prominent ways to communicate to merchants. NotificationBubbles are
   * always visible and pop up at the bottom of a page.
   *
   * ## Best practises
   * - Do use them when you otherwise users won't have any feedback.
   * - Don't use them extensive.
   * - Don't use them when no feedback is required.
   *
   * To use NotificationBubbles you need to wrap the **NotificationBubbleManager** around your app.
   * Use `sendSuccess()` exported by the NotificationBubbleManager to show a Notification.
   *
   * ```example
   * import NotificationBubbleManager, { sendSuccess } from '@allthings/elements/behaviour/NotificationBubbleManager'
   * import { Button } from '@allthings/elements'
   *
   * const ShowNotification = () => (
   *   <NotificationBubbleManager>
   *     <View>
   *       <Button onClick={() => sendSuccess('Congratulations, you clicked the Button')}>
   *         Click me!
   *       </Button>
   *     </View>
   *   </NotificationBubbleManager>
   * )
   * ```
   *
   */
};
var NotificationBubble = function (_React$Component) {
  (0, _inherits3.default)(NotificationBubble, _React$Component);

  function NotificationBubble() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, NotificationBubble);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = NotificationBubble.__proto__ || (0, _getPrototypeOf2.default)(NotificationBubble)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      visible: true
    }, _this.handleRest = function () {
      return _this.state.visible === false && _this.props.onTimeout();
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(NotificationBubble, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      setTimeout(function () {
        return _this2.setState({ visible: false });
      }, 2750);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          color = _props.color,
          onTimeout = _props.onTimeout,
          props = (0, _objectWithoutProperties3.default)(_props, ['color', 'onTimeout']);

      return _react2.default.createElement(
        _Theme2.default,
        null,
        function (_ref2) {
          var colorize = _ref2.colorize;
          return _react2.default.createElement(
            _reactMotion.Motion,
            {
              defaultStyle: { position: -50 },
              onRest: _this3.handleRest,
              style: {
                position: (0, _reactMotion.spring)(_this3.state.visible ? 50 : -100, {
                  stiffness: 180,
                  damping: 12
                })
              }
            },
            function (style) {
              return _react2.default.createElement(
                _View2.default,
                (0, _extends3.default)({}, styles.container, props),
                _react2.default.createElement(
                  _View2.default,
                  (0, _extends3.default)({}, styles.bubble, {
                    style: {
                      backgroundColor: colorize(color),
                      bottom: style.position
                    },
                    direction: 'row',
                    alignV: 'center'
                  }),
                  _react2.default.createElement(
                    _View2.default,
                    (0, _extends3.default)({ flex: 70, direction: 'row' }, styles.text),
                    _react2.default.createElement(
                      _Text2.default,
                      {
                        color: 'textOnBackground',
                        align: 'center',
                        autoBreak: true,
                        style: { width: '100%' }
                      },
                      _this3.props.children
                    )
                  ),
                  _react2.default.createElement(
                    _View2.default,
                    {
                      flex: 30,
                      alignH: 'center',
                      alignV: 'center',
                      direction: 'row'
                    },
                    _react2.default.createElement(_Icon2.default, { name: 'check-filled', color: 'white' })
                  )
                )
              );
            }
          );
        }
      );
    }
  }]);
  return NotificationBubble;
}(_react2.default.Component);

NotificationBubble.propTypes = {
  color: _propTypes2.default.string.isRequired,
  children: _propTypes2.default.node.isRequired,
  onTimeout: _propTypes2.default.func.isRequired
};
NotificationBubble.defaultProps = {
  color: 'primary',
  onTimeout: function onTimeout(_) {
    return _;
  }
};
exports.default = NotificationBubble;