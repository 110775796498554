'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTextStyles = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _glamor = require('glamor');

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _Theme = require('../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

var _neue = require('neue');

var _neue2 = _interopRequireDefault(_neue);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

if (typeof window !== 'undefined') {
  _neue2.default.load([{
    families: ['Open Sans:n4,n4i,n6,n6i'],
    css: '//fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i'
  }]);
}

var availableSizes = {
  xs: 10,
  s: 12,
  m: 13,
  l: 14,
  xl: 18,
  xxl: 18,
  giant: 24
};

var createTextStyles = exports.createTextStyles = function createTextStyles(_ref) {
  var block = _ref.block,
      italic = _ref.italic,
      strong = _ref.strong,
      size = _ref.size,
      underline = _ref.underline,
      lineThrough = _ref.lineThrough,
      align = _ref.align,
      autoBreak = _ref.autoBreak;

  return (0, _glamor.css)({
    display: block ? 'block' : 'inline',
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    fontStyle: italic && 'italic',
    fontWeight: strong && '600',
    fontSize: availableSizes[size],
    textDecoration: underline && 'underline' || lineThrough && 'line-through',
    textAlign: align,
    whiteSpace: autoBreak && 'pre-wrap',
    wordBreak: autoBreak && 'break-word',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale'
  });
};

/**
 * Text will be used for everywhere a text appears.
 * The only exception is in molecules that already provide the
 * text component for you.
 *
 * ```example
 * <Text>
 *   Text will be used for everywhere a text appears. The only exception
 *   is in molecules that already provide the text component for you.
 * </Text>
 * ```
 *
 * ```example
 * <Text autoBreak={true}>
 *   Textwillbeusedforeverywhereatextappears.Theonlyexceptionisinmoleculesthatalreadyprovidethetextcomponentforyou.Textwillbeusedforeverywhereatextappears.Theonlyexceptionisinmoleculesthatalreadyprovidethetextcomponentforyou.
 *  </Text>
 *  ```
 */

var Text = function (_React$Component) {
  (0, _inherits3.default)(Text, _React$Component);

  function Text() {
    (0, _classCallCheck3.default)(this, Text);
    return (0, _possibleConstructorReturn3.default)(this, (Text.__proto__ || (0, _getPrototypeOf2.default)(Text)).apply(this, arguments));
  }

  (0, _createClass3.default)(Text, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          color = _props.color,
          block = _props.block,
          children = _props.children,
          italic = _props.italic,
          size = _props.size,
          strong = _props.strong,
          align = _props.align,
          underline = _props.underline,
          autoBreak = _props.autoBreak,
          lineThrough = _props.lineThrough,
          props = (0, _objectWithoutProperties3.default)(_props, ['color', 'block', 'children', 'italic', 'size', 'strong', 'align', 'underline', 'autoBreak', 'lineThrough']);


      var styles = createTextStyles({
        block: block,
        italic: italic,
        strong: strong,
        size: size,
        underline: underline,
        lineThrough: lineThrough,
        align: align,
        autoBreak: autoBreak
      });

      return _react2.default.createElement(
        _Theme2.default,
        null,
        function (_ref2) {
          var colorize = _ref2.colorize;
          return _react2.default.createElement(
            _View2.default,
            (0, _extends3.default)({}, (0, _glamor.css)(styles, { color: colorize(color) }), props),
            children
          );
        }
      );
    }
  }]);
  return Text;
}(_react2.default.Component);

Text.propTypes = {
  align: _propTypes2.default.oneOf(['left', 'center', 'right']),
  autoBreak: _propTypes2.default.bool,
  block: _propTypes2.default.bool,
  children: _propTypes2.default.node,
  color: _propTypes2.default.string,
  italic: _propTypes2.default.bool,
  size: _propTypes2.default.oneOf(['xs', 's', 'm', 'l', 'xl', 'giant']),
  strong: _propTypes2.default.bool,
  underline: _propTypes2.default.bool,
  lineThrough: _propTypes2.default.bool
};
Text.defaultProps = {
  size: 'l',
  block: true,
  color: 'text'
};
exports.default = Text;