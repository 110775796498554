import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { connect } from 'redux-zero/react'
import actions from '../actions'
import HeaderBar from '../components/HeaderBar'
import { messages } from '../helper/messages'
import { getLocalDate } from '../helper/helpers'
import ListValueItem from '../components/ListValueItem'
import {
  GroupedCardList,
  ListSpinner,
  SimpleLayout,
  Spacer,
  Text,
  View,
} from '@allthings/elements'
import { sendSuccess } from '@allthings/elements/behaviour/NotificationBubbleManager'

const tenantKeys = [
  'title',
  'additional_title',
  'first_name',
  'last_name',
  'birthday',
  'email',
  'phone_mobile',
  'phone_home',
  'phone_business',
  'marital_status',
  'iban',
]

class Tenant extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    intl: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    tenant: PropTypes.object.isRequired,
  }

  componentDidUpdate(prevProps, nextProps) {
    // Trigger bubble
    if (
      prevProps.form.status !== 'success' &&
      this.props.form.status === 'success'
    ) {
      sendSuccess(this.formatMessage(messages.formSucceeded))
    }
  }

  formatMessage = (message, options) =>
    this.props.intl.formatMessage(message, options)

  renderContent() {
    const { tenant, locale } = this.props

    if (tenant.loading) {
      return (
        <View fill alignH="center" alignV="center">
          <Spacer height={20} />
          <ListSpinner />
        </View>
      )
    } else {
      return tenant && tenant.id.length ? (
        <GroupedCardList>
          {tenantKeys.map((key, i) => {
            const val =
              key === 'birthday'
                ? getLocalDate(tenant[key], locale)
                : tenant[key] || '--'
            return (
              <ListValueItem
                key={key}
                label={this.formatMessage(messages[key])}
              >
                <Text>{val}</Text>
              </ListValueItem>
            )
          })}
        </GroupedCardList>
      ) : null
    }
  }

  render() {
    return (
      <Fragment>
        <HeaderBar
          label={this.formatMessage(messages.tenantHeader)}
          lBtnIcon="arrow-left"
          lBtnTarget="/"
          rBtnIcon="edit"
          rBtnTarget="/tenant/edit"
        />
        <SimpleLayout flex="auto">{this.renderContent()}</SimpleLayout>
      </Fragment>
    )
  }
}

const mapToProps = (state, ownProps) => {
  return {
    tenant: state.tenant,
    locale: state.locale,
    form: state.form,
  }
}

export default injectIntl(connect(mapToProps, actions)(Tenant))
