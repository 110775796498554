'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Absolute = require('../atoms/Absolute');

var _Absolute2 = _interopRequireDefault(_Absolute);

var _ThemeProvider = require('../behaviour/ThemeProvider');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _glamor = require('glamor');

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var buttonStyle = (0, _glamor.css)({
  width: '100%',
  display: 'flex',
  alignItem: 'center',
  justifyContent: 'center',
  height: '100%',
  background: 'transparent',
  border: 0
});

var FloatingButton = function (_React$Component) {
  (0, _inherits3.default)(FloatingButton, _React$Component);

  function FloatingButton() {
    (0, _classCallCheck3.default)(this, FloatingButton);
    return (0, _possibleConstructorReturn3.default)(this, (FloatingButton.__proto__ || (0, _getPrototypeOf2.default)(FloatingButton)).apply(this, arguments));
  }

  (0, _createClass3.default)(FloatingButton, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          color = _props.color,
          disabled = _props.disabled,
          disabledColor = _props.disabledColor,
          props = (0, _objectWithoutProperties3.default)(_props, ['color', 'disabled', 'disabledColor']);

      return _react2.default.createElement(
        _View2.default,
        null,
        _react2.default.createElement(_View2.default, { style: { height: 50 } }),
        _react2.default.createElement(
          _Absolute2.default,
          (0, _extends3.default)({
            alignH: 'center',
            alignV: 'center',
            bottom: 0,
            direction: 'row',
            flex: 'flex'
          }, (0, _glamor.css)({
            backgroundColor: disabled ? disabledColor : color,
            boxShadow: '0px -2px 10px 0px rgba(0, 0, 0, 0.2)',
            cursor: disabled ? 'default' : 'pointer',
            height: 50,
            overflow: 'hidden',
            width: '100%'
          })),
          _react2.default.createElement('button', (0, _extends3.default)({}, buttonStyle, props))
        )
      );
    }
  }]);
  return FloatingButton;
}(_react2.default.Component);

FloatingButton.propTypes = {
  color: _propTypes2.default.string.isRequired,
  disabled: _propTypes2.default.bool,
  disabledColor: _propTypes2.default.string.isRequired
};


var mapThemeToProps = function mapThemeToProps(theme) {
  return {
    color: theme.primary,
    disabledColor: 'lightGray'
  };
};

exports.default = (0, _ThemeProvider.withTheme)(mapThemeToProps)(FloatingButton);