'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _glamor = require('glamor');

var _Text = require('../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

var _ThemeProvider = require('../behaviour/ThemeProvider');

var _color = require('../propTypes/color');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var baseStyle = {
  position: 'relative',
  padding: '8px 14px',
  borderRadius: '2px',
  userSelect: 'none',
  outline: 'none',
  border: 'none'
};

function styles(backgroundColor, color, disabled, disabledColor, disabledBackgroundColor) {
  return (0, _glamor.css)((0, _extends3.default)({}, baseStyle, {
    background: disabled ? disabledBackgroundColor : backgroundColor,
    color: disabled ? disabledColor : color,
    cursor: disabled ? 'not-allowed' : 'pointer'
  }));
}

/**
 * Buttons make common actions immediately visible and easy to perform with one
 * click or tap. They can be used for any type of action, including navigation.
 *
 * You can use two different looks for the button: primary and
 * secondary. Primary is the default type, so there's no need to explicitly
 * define it.
 *
 * ```example
 * <ThemeProvider>
 *   <Button>Hello you</Button>
 * </ThemeProvider>
 * ```
 *
 * To have an icon as button-label, just add the icon-component as children.
 *
 * ```example
 * <Button type="submit">
 *    <View direction="row">
 *      Hello with icon
 *      <View style={{ marginLeft: 10 }}>
 *        <Icon name="send-filled" size="xs" color="white" />
 *      </View>
 *    </View>
 *  </Button>
 * ```
 */

var Button = function (_React$Component) {
  (0, _inherits3.default)(Button, _React$Component);

  function Button() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Button);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Button.__proto__ || (0, _getPrototypeOf2.default)(Button)).call.apply(_ref, [this].concat(args))), _this), _this.handleClick = function (e) {
      if (!_this.props.disabled) {
        _this.props.onClick && _this.props.onClick(e);
      } else {
        e.preventDefault();
      }
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Button, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          type = _props.type,
          disabled = _props.disabled,
          backgroundColor = _props.backgroundColor,
          color = _props.color,
          disabledColor = _props.disabledColor,
          disabledBackgroundColor = _props.disabledBackgroundColor,
          cssProp = _props.css,
          restProps = (0, _objectWithoutProperties3.default)(_props, ['children', 'type', 'disabled', 'backgroundColor', 'color', 'disabledColor', 'disabledBackgroundColor', 'css']);


      var allStyles = (0, _glamor.css)(styles((0, _color.colorCode)(backgroundColor), color, disabled, disabledColor, disabledBackgroundColor), cssProp);

      return _react2.default.createElement(
        'button',
        (0, _extends3.default)({
          type: type
        }, allStyles, restProps, {
          name: restProps.name || type || null,
          onClick: this.handleClick
        }),
        typeof children === 'string' ? _react2.default.createElement(
          _Text2.default,
          { color: color },
          children
        ) : children
      );
    }
  }]);
  return Button;
}(_react2.default.Component);

Button.propTypes = {
  /** Just text most of the time */
  children: _propTypes2.default.node.isRequired,
  /** Called when the button is clicked */
  onClick: _propTypes2.default.func,
  /** Type of the button (deprecated) */
  type: _propTypes2.default.oneOf(['reset', 'button', 'submit']),
  /** Disable button state to indicate it's not touchable */
  disabled: _propTypes2.default.bool,
  /** Color of the button, theme primary color by default */
  backgroundColor: _propTypes2.default.string,
  /** Textcolor of the button (deprecated) */
  color: _color.color,
  /** Textcolor when button is disabled (deprecated) */
  disabledColor: _propTypes2.default.string,
  /** Color when button is disabled (deprecated) */
  disabledBackgroundColor: _propTypes2.default.string,
  /** Pass your own css (deprecated) */
  css: _propTypes2.default.object
};


Button.defaultProps = {
  type: 'button',
  disabled: false,
  color: 'white',
  backgroundColor: 'purple',
  disabledColor: 'darkgray',
  disabledBackgroundColor: 'lightGray'
};

var mapThemeToProps = function mapThemeToProps(theme, props) {
  return {
    backgroundColor: props.backgroundColor || theme.primary
  };
};

exports.default = (0, _ThemeProvider.withTheme)(mapThemeToProps)(Button);