'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _glamor = require('glamor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * This Component is rebuild of angular-material's flexbox directives.
 *
 * Different to angular's directive implementation, which can be used independent of each other,
 * this component combines layout and element attributes within one component.
 *
 * For explanation see:
 * - https://material.angularjs.org/latest/layout/alignment
 * - https://material.angularjs.org/latest/layout/children
 *
 * ```example
 * <ThemeProvider>
 *   <View fill direction="row" alignH="end">
 *     <Text>Say Hello!</Text>
 *   </View>
 * </ThemeProvider>
 * ```
 */
var View = function (_Component) {
  (0, _inherits3.default)(View, _Component);

  function View() {
    (0, _classCallCheck3.default)(this, View);
    return (0, _possibleConstructorReturn3.default)(this, (View.__proto__ || (0, _getPrototypeOf2.default)(View)).apply(this, arguments));
  }

  (0, _createClass3.default)(View, [{
    key: 'getCssAlignValue',


    /**
     * Appends 'flex-' to 'start' and 'end'
     * @param {String} alignment
     * @return {String}
     */
    value: function getCssAlignValue(alignment) {
      if (alignment === 'start' || alignment === 'end') {
        return 'flex-' + alignment;
      }
      return alignment;
    }

    /**
     *
     * @param {String|Number} flex
     * @returns {Object}
     */

  }, {
    key: 'getCssFlexValue',
    value: function getCssFlexValue(flex) {
      if (typeof flex === 'number') {
        if (flex === 33) flex = 100 / 3;
        if (flex === 66) flex = 200 / 3;
        return '1 1 ' + flex + '%';
      }

      /**
       * CSS value of flex: flex-grow flex-shrink flex-basis
       */
      switch (flex) {
        case 'none':
          return '0 0 auto';
        case 'flex':
          return '1';
        case 'nogrow':
          return '0 1 auto';
        case 'grow':
          return '1 1 100%';
        case 'initial':
          return '0 1 auto';
        case 'auto':
          return '1 1 auto';
        case 'noshrink':
          return '1 0 auto';
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          alignH = _props.alignH,
          alignV = _props.alignV,
          children = _props.children,
          direction = _props.direction,
          fill = _props.fill,
          flex = _props.flex,
          onRef = _props.onRef,
          wrap = _props.wrap,
          restProps = (0, _objectWithoutProperties3.default)(_props, ['alignH', 'alignV', 'children', 'direction', 'fill', 'flex', 'onRef', 'wrap']);


      var styles = {};

      if (direction || flex) {
        styles.boxSizing = 'border-box';
      }

      if (direction) {
        styles = (0, _extends3.default)({}, styles, {
          alignContent: this.getCssAlignValue(alignV),
          alignItems: this.getCssAlignValue(alignV),
          display: 'flex',
          flexDirection: direction,
          justifyContent: this.getCssAlignValue(alignH)
        });

        if (wrap) styles.flexWrap = wrap;

        if (fill) {
          styles = (0, _extends3.default)({}, styles, {
            height: '100%',
            margin: 0,
            minHeight: '100%',
            width: '100%'
          });
        }

        if (restProps.onClick) {
          styles = (0, _extends3.default)({}, styles, {
            cursor: 'pointer'
          });
        }
      }

      if (flex) {
        styles.flex = this.getCssFlexValue(flex);
      }

      return _react2.default.createElement(
        'div',
        (0, _extends3.default)({ ref: onRef }, (0, _glamor.css)(styles), restProps),
        children
      );
    }
  }]);
  return View;
}(_react.Component);

View.propTypes = {
  children: _propTypes2.default.node,

  /** horizontal alignment */
  alignH: _propTypes2.default.oneOf(['none', 'start', 'center', 'end', 'space-around', 'space-between']),

  /** vertical alignment */
  alignV: _propTypes2.default.oneOf(['none', 'start', 'center', 'end', 'stretch']),

  /** direction */
  direction: _propTypes2.default.oneOf(['row', 'column']),

  /** Passing true, will make the view fill out available space */
  fill: _propTypes2.default.bool,

  /** Defining how children will wrap */
  wrap: _propTypes2.default.oneOf(['inherit', 'initial', 'wrap', 'nowrap', 'wrap-reverse']),

  /** Flex values, can be 5, 10, 15 ... 100 or 33, 66 */
  flex: _propTypes2.default.oneOf(['none', 'flex', 'nogrow', 'grow', 'initial', 'auto', 'noshrink', 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 95, 90, 100, 33, 66]),

  onClick: _propTypes2.default.func,

  /** @deprecated */
  onRef: _propTypes2.default.func
};
View.defaultProps = {
  alignH: 'start',
  alignV: 'stretch',
  fill: false,
  flex: 'none',
  onRef: function onRef(_) {
    return _;
  } };
exports.default = View;