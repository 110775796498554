import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'

import ContactPersons from '../pages/ContactPersons'
import Home from '../pages/Home'
import Tenant from '../pages/Tenant'
import Tenancy from '../pages/Tenancy'
import Tenants from '../pages/Tenants'
import TenantEdit from '../pages/TenantEdit'
import TenantAccount from '../pages/TenantAccount'

class AppRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/tenancy/:id/contacts" component={ContactPersons} />
        <Route path="/tenancy/:id/tenants" component={Tenants} />
        <Route path="/tenancy/:id" component={Tenancy} exact={true} />
        <Route path="/tenant/edit" component={TenantEdit} />
        <Route path="/tenant/account" component={TenantAccount} />
        <Route path="/tenant" component={Tenant} />
        <Route path="/" component={Home} />
      </Switch>
    )
  }
}

export default withRouter(AppRouter)
