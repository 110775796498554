import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { connect } from 'redux-zero/react'

import {
  CardContent,
  CardFooter,
  GroupedCardList,
  ListSpinner,
  SimpleLayout,
  Spacer,
  Text,
  View,
} from '@allthings/elements'

import actions from '../actions'
import HeaderBar from '../components/HeaderBar'
import EmailButtonLink from '../components/EmailButtonLink'
import PhoneButtonLink from '../components/PhoneButtonLink'
import { messages } from '../helper/messages'
import NoContentPage from './NoContentPage'

class ContactPersons extends React.Component {
  static propTypes = {
    contactPersons: PropTypes.object.isRequired,
    getContactPersons: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.getContactPersons(this.props.match.params.id)
  }

  formatMessage = (message, options) =>
    this.props.intl.formatMessage(message, options)

  renderPhone(phone) {
    return phone ? <PhoneButtonLink phone={phone} /> : null
  }

  renderContact(person, i) {
    return (
      <GroupedCardList title={person.category} direction="column" key={i}>
        <CardContent direction="column" alignV="start">
          <Text>{person.name}</Text>
          <Text>{person.street}</Text>
          <Text>
            {person.zip} {person.city}
          </Text>
        </CardContent>
        {(person.phone_1 || person.phone_2) && (
          <CardFooter>
            {this.renderPhone(person.phone_1)}
            {this.renderPhone(person.phone_2)}
          </CardFooter>
        )}
        {person.email && (
          <CardFooter>
            <EmailButtonLink email={person.email} />
          </CardFooter>
        )}
      </GroupedCardList>
    )
  }

  renderContent() {
    const { contactPersons } = this.props
    const { persons } = contactPersons

    if (contactPersons.loading) {
      return (
        <View fill alignH="center" alignV="center">
          <Spacer height={20} />
          <ListSpinner />
        </View>
      )
    } else {
      return persons && persons.length ? (
        <Fragment>
          {persons.map((person, i) => this.renderContact(person, i))}
        </Fragment>
      ) : (
        <NoContentPage />
      )
    }
  }

  render() {
    return (
      <Fragment>
        <HeaderBar
          label={this.formatMessage(messages.contactPersonsHeader)}
          lBtnIcon="arrow-left"
          lBtnTarget="/"
        />
        <SimpleLayout flex="auto">{this.renderContent()}</SimpleLayout>
      </Fragment>
    )
  }
}

const mapToProps = (state, ownProps) => {
  return {
    contactPersons: state.contacts,
  }
}

export default injectIntl(connect(mapToProps, actions)(ContactPersons))
