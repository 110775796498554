'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * The vertical spacer is used to visually separate or create space between elements.
 */
var Spacer = function Spacer(_ref) {
  var _ref$height = _ref.height,
      height = _ref$height === undefined ? 10 : _ref$height,
      background = _ref.background;
  return _react2.default.createElement(_View2.default, (0, _glamor.css)({ width: '100%', height: height, background: background }));
};

Spacer.propTypes = {
  /** The height of space it should create **/
  height: _propTypes2.default.number,
  /** Background color, default will be transparent **/
  background: _propTypes2.default.string
};

exports.default = Spacer;