'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withTheme = exports.ThemeShape = exports.defaultTheme = exports.THEME_CHANNEL = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactBroadcast = require('react-broadcast');

var _colors = require('@allthings/colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var THEME_CHANNEL = exports.THEME_CHANNEL = 'theme';

var defaultTheme = exports.defaultTheme = {
  primary: _colors.ColorPalette.primary.blue,
  text: _colors.ColorPalette.text.primary,
  secondaryText: _colors.ColorPalette.text.secondary,
  titleColor: _colors.ColorPalette.text.primary,
  contrast: _colors.ColorPalette.white,
  warn: _colors.ColorPalette.red,
  disabled: _colors.ColorPalette.grey,
  background: _colors.ColorPalette.background.bright,
  textOnBackground: _colors.ColorPalette.white

  /**
  * All elements support theming by default, and therefore every element must be wrapped inside a ThemeProvider.
  * The ThemeProvider allows you to define the default colors for most elements.
  *
  * **Example**: If you want all you buttons to be red, instead of writing <Button color="red" /> all the time, you might want to set the "primary" color of your theme to red.
  **/
};
var ThemeProvider = function (_React$PureComponent) {
  (0, _inherits3.default)(ThemeProvider, _React$PureComponent);

  function ThemeProvider() {
    (0, _classCallCheck3.default)(this, ThemeProvider);
    return (0, _possibleConstructorReturn3.default)(this, (ThemeProvider.__proto__ || (0, _getPrototypeOf2.default)(ThemeProvider)).apply(this, arguments));
  }

  (0, _createClass3.default)(ThemeProvider, [{
    key: 'render',
    value: function render() {
      var root = true;
      if (this.context.broadcasts && this.context.broadcasts[THEME_CHANNEL]) {
        root = false;
      }
      var _props = this.props,
          theme = _props.theme,
          children = _props.children;

      return root ? _react2.default.createElement(
        _reactBroadcast.Broadcast,
        {
          channel: THEME_CHANNEL,
          value: (0, _extends3.default)({}, ThemeProvider.defaultProps.theme, theme)
        },
        children
      ) : _react2.default.createElement(
        _reactBroadcast.Subscriber,
        { channel: THEME_CHANNEL },
        function (previousTheme) {
          return _react2.default.createElement(
            _reactBroadcast.Broadcast,
            {
              channel: THEME_CHANNEL,
              value: (0, _extends3.default)({}, ThemeProvider.defaultProps.theme, previousTheme, theme)
            },
            children
          );
        }
      );
    }
  }]);
  return ThemeProvider;
}(_react2.default.PureComponent);

ThemeProvider.propTypes = {
  root: _propTypes2.default.bool,
  theme: _propTypes2.default.shape({
    primary: _propTypes2.default.string,
    text: _propTypes2.default.string,
    secondaryText: _propTypes2.default.string,
    titleColor: _propTypes2.default.string,
    contrast: _propTypes2.default.string,
    warn: _propTypes2.default.string,
    disabled: _propTypes2.default.string,
    background: _propTypes2.default.string,
    textOnBackground: _propTypes2.default.string
  }),
  children: _propTypes2.default.node
};
ThemeProvider.defaultProps = {
  theme: defaultTheme
};
ThemeProvider.contextTypes = _reactBroadcast.Subscriber.contextTypes;
exports.default = ThemeProvider;
var ThemeShape = exports.ThemeShape = ThemeProvider.propTypes.theme;

var withTheme = exports.withTheme = function withTheme(mapThemeToProps, displayName) {
  return function (WrappedComponent) {
    var _class, _temp2;

    return (
      // eslint-disable-next-line
      _temp2 = _class = function (_React$PureComponent2) {
        (0, _inherits3.default)(_class, _React$PureComponent2);

        function _class() {
          var _ref;

          var _temp, _this2, _ret;

          (0, _classCallCheck3.default)(this, _class);

          for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return _ret = (_temp = (_this2 = (0, _possibleConstructorReturn3.default)(this, (_ref = _class.__proto__ || (0, _getPrototypeOf2.default)(_class)).call.apply(_ref, [this].concat(args))), _this2), _this2.renderComponent = function (theme) {
            var props = !mapThemeToProps ? { theme: theme } : mapThemeToProps(theme, _this2.props);

            return _react2.default.createElement(WrappedComponent, (0, _extends3.default)({}, _this2.props, props));
          }, _temp), (0, _possibleConstructorReturn3.default)(_this2, _ret);
        }

        (0, _createClass3.default)(_class, [{
          key: 'render',
          value: function render() {
            return _react2.default.createElement(
              _reactBroadcast.Subscriber,
              { channel: THEME_CHANNEL },
              this.renderComponent
            );
          }
        }]);
        return _class;
      }(_react2.default.PureComponent), _class.displayName = displayName || WrappedComponent.displayName, _class.component = WrappedComponent, _temp2
    );
  };
};