'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _Icon = require('../atoms/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Text = require('../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Relative = require('../atoms/Relative');

var _Relative2 = _interopRequireDefault(_Relative);

var _ListItem = require('./List/ListItem');

var _ListItem2 = _interopRequireDefault(_ListItem);

var _glamor = require('glamor');

var _Theme = require('../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Absolute = require('../atoms/Absolute');

var _Absolute2 = _interopRequireDefault(_Absolute);

var _Inset = require('../atoms/Inset');

var _Inset2 = _interopRequireDefault(_Inset);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
  checkbox: function checkbox(background, checked) {
    return (0, _glamor.css)({
      borderRadius: '3px',
      height: '25px',
      width: '25px',
      backgroundColor: checked && background,
      borderWidth: 3,
      borderStyle: 'solid',
      borderColor: checked ? background : 'lightGrey',
      transition: '250ms'
    });
  },
  text: (0, _glamor.css)({
    width: 200,
    marginLeft: 20
  })

  /**
   * Checkbox are used to give users a way to select or deselect options.
   *
   * ```example
   * <View>
   *   <Checkbox checked name="ok" label="Are you ok?" />
   *   <Checkbox name="notok" label="Are you not ok?" />
   * </View>
   * ```
   */
};
var Checkbox = function (_React$Component) {
  (0, _inherits3.default)(Checkbox, _React$Component);

  function Checkbox() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Checkbox);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Checkbox.__proto__ || (0, _getPrototypeOf2.default)(Checkbox)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      checked: _this.props.checked
    }, _this.handleChange = function () {
      return _this.setState(function (_ref2) {
        var checked = _ref2.checked;
        return { checked: !checked };
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Checkbox, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          checked = _props.checked,
          onChange = _props.onChange,
          label = _props.label,
          labelSize = _props.labelSize,
          name = _props.name,
          backgroundColor = _props.backgroundColor,
          props = (0, _objectWithoutProperties3.default)(_props, ['checked', 'onChange', 'label', 'labelSize', 'name', 'backgroundColor']);

      var realChecked = checked || this.state.checked;
      var changeHandler = onChange || this.handleChange;
      return _react2.default.createElement(
        _Theme2.default,
        null,
        function (_ref3) {
          var theme = _ref3.theme,
              colorize = _ref3.colorize;
          return _react2.default.createElement(
            _ListItem2.default,
            { backgroundColor: colorize(backgroundColor) },
            _react2.default.createElement(
              _View2.default,
              { direction: 'row', alignV: 'center' },
              _react2.default.createElement(
                _Relative2.default,
                (0, _extends3.default)({
                  direction: 'row',
                  alignV: 'center',
                  alignH: 'center'
                }, styles.checkbox(theme.primary, realChecked)),
                realChecked && _react2.default.createElement(
                  _Relative2.default,
                  { bottom: 1 },
                  _react2.default.createElement(_Icon2.default, { name: 'check-filled', size: 14, color: '#fff' })
                ),
                _react2.default.createElement(
                  _Absolute2.default,
                  { top: 0, left: 0, right: 0, bottom: 0 },
                  _react2.default.createElement('input', (0, _extends3.default)({
                    type: 'checkbox',
                    checked: realChecked,
                    id: name,
                    value: realChecked,
                    style: {
                      opacity: 0,
                      width: '25px',
                      height: '25px',
                      margin: 0
                    },
                    onChange: changeHandler
                  }, props))
                )
              )
            ),
            _react2.default.createElement(
              'label',
              { htmlFor: name },
              _react2.default.createElement(
                _Inset2.default,
                { horizontal: true },
                _react2.default.createElement(
                  _Text2.default,
                  { size: labelSize },
                  label
                )
              )
            )
          );
        }
      );
    }
  }]);
  return Checkbox;
}(_react2.default.Component);

Checkbox.propTypes = {
  /** True to make it checked */
  checked: _propTypes2.default.bool,
  /** Label of Checkbox */
  label: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.string]).isRequired,
  /** Text size of the label */
  labelSize: _Text2.default.propTypes.size,
  name: _propTypes2.default.string.isRequired,
  onChange: _propTypes2.default.func,
  /** Background color of the form item */
  backgroundColor: _propTypes2.default.string
};
Checkbox.defaultProps = {
  checked: false,
  labelSize: 'l'
};
exports.default = Checkbox;