import PropTypes from 'prop-types'
import React from 'react'
import { ListItem, Text, View } from '@allthings/elements'

export default class ListValueItem extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
  }

  render() {
    const { label, style, children } = this.props
    return (
      <ListItem style={style}>
        <View fill direction="row" alignH="space-between">
          <Text strong>{label}</Text>
          {children}
        </View>
      </ListItem>
    )
  }
}
