'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _glamor = require('glamor');

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _color = require('../propTypes/color');

var _ThemeProvider = require('../behaviour/ThemeProvider');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var box = function box(background) {
  return (0, _glamor.css)({
    height: 50,
    backgroundColor: (0, _color.colorCode)(background),
    position: 'relative',
    zIndex: 2
  });
};

/**
 * Title bar is used to give user control and information about navigation.
 *
 * ```example
 * <ThemeProvider>
 *   <TitleBar alignH="space-between" color="blueIntense">
 *     <View direction="row" alignV="center">
 *       <SquareIconButton icon="armchair-filled" iconColor="white" />
 *       <Text color="white" strong>
 *         Get Relaxed
 *       </Text>
 *     </View>
 *     <SquareIconButton icon="search-filled" iconColor="white" />
 *   </TitleBar>
 * </ThemeProvider>
 * ```
 */

var TitleBar = function (_React$Component) {
  (0, _inherits3.default)(TitleBar, _React$Component);

  function TitleBar() {
    (0, _classCallCheck3.default)(this, TitleBar);
    return (0, _possibleConstructorReturn3.default)(this, (TitleBar.__proto__ || (0, _getPrototypeOf2.default)(TitleBar)).apply(this, arguments));
  }

  (0, _createClass3.default)(TitleBar, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          color = _props.color,
          props = (0, _objectWithoutProperties3.default)(_props, ['children', 'color']);

      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({ direction: 'row', alignV: 'center' }, props, box(color)),
        children
      );
    }
  }]);
  return TitleBar;
}(_react2.default.Component);

TitleBar.propTypes = {
  /** Callback when title bar is clicked */
  onClick: _propTypes2.default.func,
  /** Color of the title bar */
  color: _propTypes2.default.string,
  children: _propTypes2.default.node
};
TitleBar.defaultProps = {
  color: 'grey'
};


var mapThemeToProps = function mapThemeToProps(theme) {
  return {
    color: theme.primary
  };
};

exports.default = (0, _ThemeProvider.withTheme)(mapThemeToProps, 'TitleBar')(TitleBar);