'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _glamor = require('glamor');

var _Text = require('../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _Theme = require('../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(backgroundColor) {
  return (0, _glamor.css)({
    backgroundColor: backgroundColor,
    display: 'inline-block',
    borderRadius: 10,
    padding: '2px 15px',
    cursor: 'default'
  });
};

/**
 * Pills 💊 are used to show status or to highlight a piece of information.
 * It could be used on cards to indicate that this card contains important information.
 *
 * ```example
 * <ThemeProvider>
 *   <View>
 *     <Pill label="Important message" color="primary" />
 *     <Pill label="A warning" color="warn" />
 *   </View>
 * </ThemeProvider>
 * ```
 */
var Pill = function Pill(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === undefined ? 'primary' : _ref$color,
      label = _ref.label,
      props = (0, _objectWithoutProperties3.default)(_ref, ['color', 'label']);

  return _react2.default.createElement(
    _Theme2.default,
    null,
    function (_ref2) {
      var colorize = _ref2.colorize;
      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({}, styles(colorize(color)), props),
        _react2.default.createElement(
          _Text2.default,
          { size: 's', color: 'textOnBackground', strong: true },
          label
        )
      );
    }
  );
};

Pill.propTypes = {
  /** Text that shows on the pill **/
  label: _propTypes2.default.string.isRequired,
  /** Themed color of the pill **/
  color: _propTypes2.default.string
};

exports.default = Pill;