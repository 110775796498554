'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Lists display a series of related content.
 * Make sure to check out ListItem, ListIcon and ListSpinner to
 * get the maximum out of it.
 *
 * ```example
 * <Card>
 *  <List>
 *    <ListItem>
 *      <Text>Item 1</Text>
 *    </ListItem>
 *    <ListItem>
 *      <Text>Item 2</Text>
 *    </ListItem>
 *    <ListItem>
 *      <Text>Item 3</Text>
 *    </ListItem>
 *    <ListItem>
 *      <Text>Item 4</Text>
 *    </ListItem>
 *    <ChevronRightListItem onClick={_ => _}>
 *      <Text>ChevronRightListItem to show indicator</Text>
 *    </ChevronRightListItem>
 *  </List>
 * </Card>
 * ```
 *
 * ```example
 * <View>
 *  <GroupedCardList title="A list with images">
 *    <View direction="row">
 *      <Image src={'https://placeimg.com/80/80/people?t=1'} />
 *      <ChevronRightListItem flex="grow">
 *        <Text>Jules</Text>
 *      </ChevronRightListItem>
 *    </View>
 *    <View direction="row">
 *      <Image src={'https://placeimg.com/80/80/people?t=2'} />
 *      <ListItem flex="grow">
 *        <Text>Luan</Text>
 *      </ListItem>
 *    </View>
 *    <View direction="row">
 *      <Image src={'https://placeimg.com/80/80/people?t=3'} />
 *      <ListItem flex="grow">
 *        <Text>Uli</Text>
 *      </ListItem>
 *    </View>
 *  </GroupedCardList>
 *  <ListSpinner />
 * </View>
 * ```
 */
var List = function List(_ref) {
  var children = _ref.children,
      restProps = (0, _objectWithoutProperties3.default)(_ref, ['children']);

  return _react2.default.createElement(
    _View2.default,
    (0, _extends3.default)({ direction: 'column' }, restProps),
    children
  );
};

List.propTypes = {
  /**
   * Use a ListItem or ChevronRightListItem,
   * or any View component.
   * */
  children: _propTypes2.default.node
};

exports.default = List;