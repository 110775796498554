import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { connect } from 'redux-zero/react'
import actions from '../actions'
import HeaderBar from '../components/HeaderBar'
import { ColorPalette, alpha } from '@allthings/colors'
import { messages } from '../helper/messages'
import { getLocalDate } from '../helper/helpers'
import ListValueItem from '../components/ListValueItem'
import {
  GroupedCardList,
  ListSpinner,
  SimpleLayout,
  Text,
  View,
} from '@allthings/elements'

// Keys that should be displayed
const accountKeys = [
  'due_date',
  'balance',
  'amount_claimed',
  'amount_paid',
  'claim_type',
]

class TenantAccount extends React.Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired,
    getTenantAccount: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getTenantAccount()
  }

  formatMessage = (message, options) =>
    this.props.intl.formatMessage(message, options)

  renderPositions() {
    const { account, locale } = this.props

    return account.invoice_items && account.invoice_items.length
      ? account.invoice_items.map((item, i) => {
          return (
            <GroupedCardList
              key={`${item.claim_type}${item.description}`}
              title={item.description}
            >
              {accountKeys.map((key, i) => {
                const val =
                  key === 'due_date'
                    ? getLocalDate(item[key], locale)
                    : item[key] || '--'
                return (
                  <ListValueItem
                    key={key}
                    label={this.formatMessage(messages[key])}
                  >
                    <Text>{val}</Text>
                  </ListValueItem>
                )
              })}
            </GroupedCardList>
          )
        })
      : null
  }

  renderContent() {
    const { account } = this.props

    if (account.loading) {
      return (
        <View fill alignH="center" alignV="center">
          <ListSpinner />
        </View>
      )
    } else {
      return account && account.balance_total + '' ? (
        <Fragment>
          <GroupedCardList>
            <strong>
              <ListValueItem
                label={this.formatMessage(messages.balance_total)}
                style={{
                  backgroundColor:
                    account.balance_total < 0
                      ? alpha(ColorPalette.red, 0.25)
                      : alpha(ColorPalette.mint, 0.25),
                }}
              >
                <Text>{account.balance_total}</Text>
              </ListValueItem>
            </strong>
          </GroupedCardList>
          {this.renderPositions()}
        </Fragment>
      ) : null
    }
  }

  render() {
    return (
      <Fragment>
        <HeaderBar
          label={this.formatMessage(messages.tenantAccountHeader)}
          lBtnIcon="arrow-left"
          lBtnTarget="/"
        />
        <SimpleLayout flex="auto">{this.renderContent()}</SimpleLayout>
      </Fragment>
    )
  }
}

const mapToProps = (state, ownProps) => {
  return {
    locale: state.locale,
    tenant: state.tenant,
    account: state.account,
  }
}

export default injectIntl(connect(mapToProps, actions)(TenantAccount))
