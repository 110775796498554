'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _Input = require('../atoms/Input');

var _Input2 = _interopRequireDefault(_Input);

var _Text = require('../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

var _CountryList = require('../utils/CountryList');

var _CountryList2 = _interopRequireDefault(_CountryList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var countryCodes = _CountryList2.default.map(function (country) {
  return country.code.toString();
});

var matchNumber = function matchNumber(original) {
  return original ? original.match(/[0-9]/gm) : '';
};

var removeFormat = function removeFormat(original) {
  return matchNumber(original) ? matchNumber(original).join('') : '';
};

var findArea = function findArea(areaCodes, pureNumber, countryCode, areaLength) {
  // if country has areas property, check if entered area code exists
  if (areaCodes) {
    var areaBeginsZero = areaCodes.find(function (area) {
      return area.split('')[0] === '0';
    });
    var area = pureNumber.substring(countryCode.length);
    var zeroExists = !areaBeginsZero && area[0] === '0';
    var offset = zeroExists ? 1 : 0;
    var testAreaCode = area.substring(offset, areaLength + offset);
    var areaExists = areaCodes.indexOf(testAreaCode);
    return areaExists !== -1 ? { validAreaCode: testAreaCode, zeroExists: zeroExists } : false;
  }

  return false;
};

var checkFormat = function checkFormat(countryIndex, pureNumber, areaCode) {
  // if country has format property, format accordingly
  var _ref = _CountryList2.default[countryIndex] || '',
      code = _ref.code,
      format = _ref.format,
      areas = _ref.areas;

  var _ref2 = areaCode || '',
      validAreaCode = _ref2.validAreaCode,
      zeroExists = _ref2.zeroExists;

  var numberInFormat = format ? format.split('').reduce(function (acc, curr) {
    return curr === 'X' ? acc + 1 : acc;
  }, 0) : '';
  var countryCode = code ? code.toString() : '';
  var countryCodeLength = code ? countryCode.length : '';
  var codeLength = validAreaCode ? validAreaCode.length + countryCodeLength : countryCodeLength;
  var offset = zeroExists ? 1 : 0;
  var removedCodes = pureNumber.substring(codeLength + offset);
  var numberX = 0;
  var formattingArea = format && pureNumber.length <= numberInFormat + codeLength + offset && !!areas === !!validAreaCode ? format.split('').map(function (letter, index) {
    if (index <= pureNumber.length + numberX) {
      if (letter === 'C') {
        numberX++;
        return zeroExists ? countryCode + ' (0)' : countryCode;
      } else if (letter === 'A') {
        numberX++;
        return validAreaCode;
      } else if (letter === 'X') {
        return removedCodes[index - numberX];
      }

      numberX++;
      return index >= removedCodes.length + numberX ? undefined : letter;
    }
  }) : // spain's (+39) area codes starts with 0, and keeps the 0 even when calling internationally
  pureNumber[countryCodeLength] === '0' && countryCode !== '39' ? [countryCode, ' (0) ', validAreaCode, removedCodes.substring(pureNumber.length <= numberInFormat + codeLength + 1 ? 1 : pureNumber.length > numberInFormat + codeLength + 1 && pureNumber[countryCodeLength] === '0' && !validAreaCode ? 1 : 0)] : code ? [code, ' ', validAreaCode, removedCodes] : pureNumber;

  return ['+'].concat((0, _toConsumableArray3.default)(formattingArea));
};

var formatInput = function formatInput(countryIndex, pureNumber) {
  var _ref3 = _CountryList2.default[countryIndex] || '',
      code = _ref3.code,
      areas = _ref3.areas;

  var countryCode = code ? code.toString() : '';
  var areaCodes = areas ? areas.map(function (area) {
    return area.toString();
  }) : '';
  var maxAreaDigits = areaCodes ? areaCodes.reduce(function (acc, curr) {
    return curr.length > acc ? curr.length : acc;
  }, []) : '';

  // if country has area codes, check to see if inputted area code exists
  var loopAreas = [].concat((0, _toConsumableArray3.default)(Array(maxAreaDigits))).map(function (_, index) {
    return findArea(areaCodes, pureNumber, countryCode, index + 1);
  });
  var validAreaObj = loopAreas.find(function (areaCode) {
    return areaCode !== false;
  });
  // check if format of this country is available
  var formatted = checkFormat(countryIndex, pureNumber, validAreaObj);

  return formatted.join('');
};

var formattedNumber = function formattedNumber(number) {
  var joinedMatch = removeFormat(number);
  var pureNumber = joinedMatch.substring(0, 2) === '00' ? joinedMatch.substring(2) : joinedMatch[0] === '0' ? joinedMatch.substring(1) : joinedMatch;

  // check first 3 digits of pureNumber to see if a country matches, else return pureNumber
  var loopThreeDigits = [].concat((0, _toConsumableArray3.default)(Array(3))).map(function (_, index) {
    return countryCodes.indexOf(pureNumber.substring(0, index + 1));
  });
  var countryIndex = loopThreeDigits.find(function (countryCode) {
    return countryCode !== -1;
  });
  var formatted = formatInput(countryIndex, pureNumber);
  var formatSubstring = formatted.substring(0, formatted.length);
  return countryIndex === 0 || pureNumber ? formatSubstring : pureNumber;
};

/**
 * PhoneInputs are used to enter international phone numbers - country codes are mandatory.
 * For non-international numbers, please see our TextInput component.
 *
 * Features:
 * Entered information will automatically be formatted according to the country code.
 * Currently, area codes are only formatted for Austria, France, Germany, Italy, Portugal, Switzerland, and the United States.
 *
 * ```example
 * <PhoneInput name="phone" placeholder="Example Placeholder" defaultValue="4907615555555" required />
 * ```
 **/

var PhoneInput = function (_Component) {
  (0, _inherits3.default)(PhoneInput, _Component);

  function PhoneInput() {
    var _ref4;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, PhoneInput);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref4 = PhoneInput.__proto__ || (0, _getPrototypeOf2.default)(PhoneInput)).call.apply(_ref4, [this].concat(args))), _this), _this.state = {
      numberEntered: false,
      backspaced: false,
      deleted: false,
      value: _this.props.defaultValue,
      spanZ: 10,
      placeholder: '+'
    }, _this.componentDidMount = function () {
      var formatted = formattedNumber(_this.textInput.value);

      _this.setState({ value: formatted, spanZ: formatted ? 0 : 10 });
    }, _this.formatNumber = function (event) {
      var input = _this.textInput;
      var _this$state = _this.state,
          backspaced = _this$state.backspaced,
          _this$state$backspace = _this$state.backspaced,
          end = _this$state$backspace.end,
          offset = _this$state$backspace.offset,
          previous = _this$state$backspace.previous,
          numberEntered = _this$state.numberEntered,
          deleted = _this$state.deleted;
      var selectionStart = event.target.selectionStart;

      var formatted = formattedNumber(removeFormat(event.target.value));

      _this.setState({ value: formatted }, function () {
        var beforeSelection = previous ? previous.substring(0, end) : '';
        var _this$state2 = _this.state,
            nonNumber = _this$state2.nonNumber,
            value = _this$state2.value;
        // differentiate if backspaced or number is entered, then setSelectionRange accordingly

        if ((value === '+' || beforeSelection === '+') && !numberEntered) {
          input.setSelectionRange(1, 1);
        } else if (backspaced) {
          var previousSubstring = previous.substring(0, selectionStart);
          var currentSubstring = input.value.substring(0, selectionStart);
          var lastNumber = formatted.split('').findIndex(function (_, index) {
            return removeFormat(formatted.substring(0, index)) === removeFormat(previousSubstring);
          });
          var position = previousSubstring === currentSubstring ? selectionStart : previous.length <= value.length && lastNumber !== -1 ? lastNumber : previous.length <= value.length ? value.length : end - nonNumber[0] - nonNumber[1] - offset;
          input.setSelectionRange(position, position);
        } else if (deleted || deleted === 0) {
          input.setSelectionRange(deleted, deleted);
        } else if (numberEntered || numberEntered === 0) {
          var nextNumber = formatted.substring(numberEntered).split('');
          var nextNumberIndex = nextNumber.findIndex(function (number) {
            return matchNumber(number) !== null;
          });
          var _position = nextNumberIndex + selectionStart;

          input.setSelectionRange(_position, _position);
        }

        _this.setState({
          numberEntered: false,
          backspaced: false,
          deleted: false,
          spanZ: input.value ? 0 : 10
        });
        _this.props.onChange && _this.props.onChange(removeFormat(formatted));
      });
    }, _this.handleKeyDown = function (event) {
      var input = _this.textInput;
      var start = input.selectionStart,
          end = input.selectionEnd,
          value = input.value;
      var key = event.keyCode,
          shiftKey = event.shiftKey,
          altKey = event.altKey,
          metaKey = event.metaKey;

      // make sure no shifts etc are pressed (to prevent non-numbers being entered)

      if (!shiftKey && !altKey && !metaKey) {
        // if (backspaced || deleted); else if (number is entered)
        if (key === 8) {
          if (start === end) {
            event.preventDefault();
            var closestNumber = value.substring(0, start).split('').reduce(function (acc, curr, index) {
              return matchNumber(curr) !== null ? {
                number: [index, acc.number[0]],
                nonNumber: [0, acc.nonNumber[0]]
              } : {
                number: acc.number,
                nonNumber: [acc.nonNumber[0] + 1, acc.nonNumber[1]]
              };
            }, { number: [0, 0], nonNumber: [0, 0] });

            _this.setState({
              backspaced: { end: end, offset: 1, previous: value },
              nonNumber: closestNumber.nonNumber,
              value: value.substring(0, closestNumber.number[0]) + value.substring(end)
            }, function () {
              _this.formatNumber({
                target: {
                  selectionStart: closestNumber.number[0],
                  value: _this.state.value
                }
              });
            });
          } else {
            _this.setState({
              backspaced: { end: start, offset: 0, previous: value },
              nonNumber: [0, 0]
            });
          }
        } else if (key === 46) {
          if (start === end) {
            var firstNumber = !matchNumber(value[end]) ? 1 + end + value.substring(start).split('').findIndex(function (number) {
              return number.match(/[0-9]/g) !== null;
            }) : end;
            _this.setState({ deleted: start });
            input.setSelectionRange(start, firstNumber);
          } else {
            _this.setState({ deleted: start });
          }
        } else if ((key >= 48 && key <= 57 || key >= 96 && key <= 105) && start !== value.length) {
          _this.setState({ numberEntered: start });
        }
      }
    }, _this.handleFocus = function () {
      var value = _this.textInput.value;

      _this.setState({ spanZ: value ? 0 : 10 });
      // time out of 0sec b/c setSelectionRange will only execute properly after focus event
      setTimeout(function () {
        var cursorPosition = value === '+' ? 1 : value.length;
        _this.textInput.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }, _this.handleBlur = function () {
      return _this.textInput.value || _this.setState({ spanZ: 10, value: '' });
    }, _this.handleClick = function () {
      return _this.textInput.focus();
    }, _this.createRef = function (node) {
      return _this.textInput = node;
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(PhoneInput, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          onChange = _props.onChange,
          props = (0, _objectWithoutProperties3.default)(_props, ['onChange']);

      var styles = {
        wrapper: {
          position: 'relative',
          cursor: 'text',
          width: '100%'
        },
        span: {
          marginLeft: '5px',
          userSelect: 'none',
          position: 'absolute',
          zIndex: this.state.spanZ,
          width: '100%',
          top: '50%',
          transform: 'translateY(-50%)'
        }
      };

      return _react2.default.createElement(
        'div',
        { style: styles.wrapper, onClick: this.handleClick },
        _react2.default.createElement(
          _Text2.default,
          { style: styles.span, size: 'm' },
          this.state.placeholder
        ),
        _react2.default.createElement(_Input2.default, (0, _extends3.default)({
          onInputRef: this.createRef,
          name: 'phone',
          type: 'tel',
          value: this.state.value,
          onKeyDown: this.handleKeyDown,
          onChange: this.formatNumber,
          onFocus: this.handleFocus,
          onBlur: this.handleBlur
        }, props))
      );
    }
  }]);
  return PhoneInput;
}(_react.Component);

PhoneInput.propTypes = {
  /** Indicates that this field is required */
  required: _propTypes.bool,
  /** The name of this input field */
  name: _propTypes.string.isRequired,
  /** Called, when the users changes something */
  onChange: _propTypes.func,
  /** Prefilled default value (optional) */
  defaultValue: _propTypes.string,
  /** Value of placeholder */
  placeholder: _propTypes.string
};
exports.default = PhoneInput;