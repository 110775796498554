'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _Spinner = require('../../atoms/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _glamor = require('glamor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
  spinner: (0, _glamor.css)({
    height: 'auto',
    width: 'auto',
    borderRadius: '50%',
    margin: 'auto'
  })
};

var SwitchListSpinner = function SwitchListSpinner(_ref) {
  var width = _ref.width,
      fill = _ref.fill,
      props = (0, _objectWithoutProperties3.default)(_ref, ['width', 'fill']);
  return _react2.default.createElement(
    _View2.default,
    (0, _extends3.default)({
      direction: 'row',
      alignH: 'center',
      alignV: 'center'
    }, (0, _glamor.css)(styles.spinner, { background: fill }), props),
    _react2.default.createElement(_Spinner2.default, null)
  );
};

SwitchListSpinner.propTypes = {
  width: _propTypes2.default.number,
  fill: _propTypes2.default.string
};

exports.default = SwitchListSpinner;