'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _glamor = require('glamor');

var _colors = require('@allthings/colors');

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(backgroundColor, withCursor, hideLine, padded) {
  return (0, _glamor.css)({
    padding: padded && '10px 15px',
    minHeight: '50px',
    borderBottom: !hideLine && '1px solid ' + _colors.ColorPalette.lightGrey,
    cursor: withCursor && 'pointer',
    backgroundColor: backgroundColor
  });
};

var ListItem = function ListItem(_ref) {
  var children = _ref.children,
      backgroundColor = _ref.backgroundColor,
      hideLine = _ref.hideLine,
      padded = _ref.padded,
      props = (0, _objectWithoutProperties3.default)(_ref, ['children', 'backgroundColor', 'hideLine', 'padded']);
  return _react2.default.createElement(
    _View2.default,
    (0, _extends3.default)({
      direction: 'row',
      alignV: 'center'
    }, styles(backgroundColor, !!props.onClick, hideLine, padded), props),
    children
  );
};

ListItem.propTypes = {
  children: _propTypes2.default.node,
  hideLine: _propTypes2.default.bool,
  padded: _propTypes2.default.bool,
  onClick: _propTypes2.default.func,
  backgroundColor: _propTypes2.default.string
};

ListItem.defaultProps = {
  backgroundColor: 'white',
  padded: true
};

exports.default = ListItem;