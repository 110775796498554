'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

var _Text = require('../../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

var _ThemeProvider = require('../../behaviour/ThemeProvider');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SwitchListItem = function (_React$Component) {
  (0, _inherits3.default)(SwitchListItem, _React$Component);

  function SwitchListItem() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, SwitchListItem);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = SwitchListItem.__proto__ || (0, _getPrototypeOf2.default)(SwitchListItem)).call.apply(_ref, [this].concat(args))), _this), _this.handleClick = function () {
      return _this.props.onClick(_this.props.optionKey);
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(SwitchListItem, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          optionKey = _props.optionKey,
          value = _props.value,
          isActive = _props.isActive,
          onClick = _props.onClick,
          children = _props.children,
          theme = _props.theme,
          props = (0, _objectWithoutProperties3.default)(_props, ['optionKey', 'value', 'isActive', 'onClick', 'children', 'theme']);

      var activeStyle = isActive && {
        backgroundColor: theme.primary
      };
      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({
          onClick: this.handleClick,
          'data-e2e': 'settings-switch-locale-' + this.props.optionKey
        }, (0, _glamor.css)(activeStyle, { ':hover': { cursor: 'pointer' } }), props),
        _react2.default.createElement(
          _Text2.default,
          (0, _extends3.default)({
            size: 'm',
            color: isActive ? 'textOnBackground' : 'gray'
          }, (0, _glamor.css)({
            padding: 10,
            textAlign: 'center'
          })),
          value
        ),
        children
      );
    }
  }]);
  return SwitchListItem;
}(_react2.default.Component);

SwitchListItem.propTypes = {
  optionKey: _propTypes2.default.string.isRequired,
  value: _propTypes2.default.string.isRequired,
  isActive: _propTypes2.default.bool,
  onClick: _propTypes2.default.func.isRequired,
  children: _propTypes2.default.node,
  theme: _propTypes2.default.object.isRequired
};
exports.default = (0, _ThemeProvider.withTheme)()(SwitchListItem);