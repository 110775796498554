'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _Spinner = require('../../atoms/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _Circle = require('../../atoms/Circle');

var _Circle2 = _interopRequireDefault(_Circle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/** It's a spinner for a list **/
var ListSpinner = function ListSpinner(props) {
  return _react2.default.createElement(
    _View2.default,
    { direction: 'row', alignH: 'center' },
    _react2.default.createElement(
      _Circle2.default,
      (0, _extends3.default)({ radius: 40, color: 'white' }, props),
      _react2.default.createElement(_Spinner2.default, null)
    )
  );
};

exports.default = ListSpinner;