'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Inset = require('../atoms/Inset');

var _Inset2 = _interopRequireDefault(_Inset);

var _Text = require('../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GroupTitle = function GroupTitle(_ref) {
  var children = _ref.children;
  return _react2.default.createElement(
    _Inset2.default,
    { horizontal: true, vertical: true },
    _react2.default.createElement(
      _Text2.default,
      { size: 'l', strong: true, color: 'titleColor' },
      children
    )
  );
};

GroupTitle.propTypes = {
  children: _propTypes2.default.node
};

exports.default = GroupTitle;