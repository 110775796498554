'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var STATES = ['badInput', 'customError', 'patternMismatch', 'rangeOverflow', 'rangeUnderflow', 'stepMismatch', 'tooLong', 'tooShort', 'typeMismatch', 'valueMissing'];

var validityPropTypes = STATES.reduce(function (acc, prop) {
  return (0, _extends4.default)({}, acc, (0, _defineProperty3.default)({}, prop, _propTypes2.default.string));
}, {});

/**
 * The FormValidityProvider gives a nice and simple way for injecting custom
 * error messages that are mapping the HTML5 constraint validation API.
 *
 * Please check the following documentation:
 * https://developer.mozilla.org/en-US/docs/Learn/HTML/Forms/Form_validation#Validating_forms_using_JavaScript
 */

var FormValidityProvider = function (_React$Component) {
  (0, _inherits3.default)(FormValidityProvider, _React$Component);

  function FormValidityProvider() {
    (0, _classCallCheck3.default)(this, FormValidityProvider);
    return (0, _possibleConstructorReturn3.default)(this, (FormValidityProvider.__proto__ || (0, _getPrototypeOf2.default)(FormValidityProvider)).apply(this, arguments));
  }

  (0, _createClass3.default)(FormValidityProvider, [{
    key: 'getChildContext',
    value: function getChildContext() {
      return { validity: this.props.validity, STATES: STATES };
    }
  }, {
    key: 'render',
    value: function render() {
      return this.props.children;
    }
  }]);
  return FormValidityProvider;
}(_react2.default.Component);

FormValidityProvider.childContextTypes = {
  validity: _propTypes2.default.shape(validityPropTypes),
  STATES: _propTypes2.default.array
};
FormValidityProvider.propTypes = {
  validity: _propTypes2.default.shape(validityPropTypes),
  children: _propTypes2.default.node.isRequired
};
FormValidityProvider.defaultProps = {
  validity: {},
  children: null
};
exports.default = FormValidityProvider;