'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Icon = require('../atoms/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _glamor = require('glamor');

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _color = require('../propTypes/color');

var _ThemeProvider = require('../behaviour/ThemeProvider');

var _Responsive = require('../behaviour/Responsive');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var box = function box(background, onClick) {
  var _css;

  return (0, _glamor.css)((_css = {
    height: 50,
    width: 50,
    borderRadius: 2,
    backgroundColor: background
  }, (0, _defineProperty3.default)(_css, (0, _Responsive.createMQ)('mobile'), {
    height: 40,
    width: 40
  }), (0, _defineProperty3.default)(_css, ':hover', {
    cursor: onClick && 'pointer'
  }), _css));
};

/**
 * Button with only an icon. Can be used in toolbars. May also be used
 * for back-buttons in the titlebar.
 *
 * ```example
 * <SquareIconButton icon="armchair-filled" color="red" iconColor="white" />
 * ```
 */

var SquareIconButton = function (_React$Component) {
  (0, _inherits3.default)(SquareIconButton, _React$Component);

  function SquareIconButton() {
    (0, _classCallCheck3.default)(this, SquareIconButton);
    return (0, _possibleConstructorReturn3.default)(this, (SquareIconButton.__proto__ || (0, _getPrototypeOf2.default)(SquareIconButton)).apply(this, arguments));
  }

  (0, _createClass3.default)(SquareIconButton, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          icon = _props.icon,
          color = _props.color,
          iconColor = _props.iconColor,
          iconSize = _props.iconSize,
          onClick = _props.onClick;

      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({}, box((0, _color.colorCode)(color), onClick), {
          direction: 'row',
          alignH: 'center',
          alignV: 'center',
          onClick: onClick
        }),
        _react2.default.createElement(_Icon2.default, { color: iconColor, size: iconSize, name: icon })
      );
    }
  }]);
  return SquareIconButton;
}(_react2.default.Component);

SquareIconButton.propTypes = {
  icon: _propTypes2.default.string.isRequired,
  color: _color.color,
  /** Size of the icon child component (check <Icon />) **/
  iconSize: _propTypes2.default.string,
  iconColor: _propTypes2.default.string,
  onClick: _propTypes2.default.func
};
SquareIconButton.defaultProps = {
  color: 'transparent',
  iconColor: 'lightBlack',
  iconSize: 's'
};


var mapThemeToProps = function mapThemeToProps(theme) {
  return {
    textColor: theme.text
  };
};

exports.default = (0, _ThemeProvider.withTheme)(mapThemeToProps)(SquareIconButton);