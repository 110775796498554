'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Images make thing more interesting. They can be used
 * to display user image content and UI graphics.
 * If something goes wrong when loading the image, a placeholder will
 * be shown instead.
 *
 * ```example
 * <Image
 *   style={{width: 225, height: 225}}
 *   size="cover"
 *   src={'https://placeimg.com/225/225/people'}
 * />
 * ```
 *
 * ```example
 * <Image
 * style={{width: 225, height: 225}}
 * src={'https://placeimg.com/nothing'}
 * />
 * ```
 */
var Image = function (_React$Component) {
  (0, _inherits3.default)(Image, _React$Component);

  function Image() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Image);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Image.__proto__ || (0, _getPrototypeOf2.default)(Image)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      useFallback: false
    }, _this.loadImage = function (src) {
      var image = new Image();
      image.onerror = _this.onError;
      image.src = src;
    }, _this.getFallbackUrl = function () {
      var baseUrl = typeof _this.context.resourcePath === 'undefined' ? 'https://static.allthings.me/app/prod/' : _this.context.resourcePath;

      return baseUrl + '/static/img/default/image.svg';
    }, _this.onError = function () {
      return _this.props.srcFallback && _this.setState({ useFallback: true });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Image, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.loadImage(this.props.src);
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      this.setState({ useFallback: false });
      if (nextProps.src !== this.props.src) {
        this.loadImage(nextProps.src);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          srcFallback = _props.srcFallback,
          src = _props.src,
          position = _props.position,
          size = _props.size,
          props = (0, _objectWithoutProperties3.default)(_props, ['srcFallback', 'src', 'position', 'size']);


      var imageUrl = this.state.useFallback ? srcFallback || this.getFallbackUrl() : src;

      return _react2.default.createElement(_View2.default, (0, _extends3.default)({}, (0, _glamor.css)({
        backgroundImage: 'url(' + imageUrl + ')',
        backgroundSize: size,
        backgroundPosition: position
      }), props));
    }
  }]);
  return Image;
}(_react2.default.Component);

Image.propTypes = {
  /** Alternative image to use */
  alt: _propTypes2.default.string,
  /** Will be called when the image is clicked */
  onClick: _propTypes2.default.func,
  /** The URL of the image */
  src: _propTypes2.default.string.isRequired,
  /** The URL of the fallback image */
  srcFallback: _propTypes2.default.string,
  /** The behaviour behavior of image within the container */
  size: _propTypes2.default.oneOf(['contain', 'cover']),
  /** The position of image */
  position: _propTypes2.default.oneOf(['center', 'left', 'right', 'top', 'bottom'])
};
Image.contextTypes = {
  resourcePath: _propTypes2.default.string
};
exports.default = Image;