import PropTypes from 'prop-types'
import React from 'react'
import { css } from 'glamor'

const container = css({
  flexGrow: 100,
  flexShrink: 0,
  width: 280,
  maxWidth: 450,
})

const TextColumn = ({ children }) => {
  return <div {...container}>{children}</div>
}

TextColumn.propTypes = {
  children: PropTypes.node,
}

export default TextColumn
