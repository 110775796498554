'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

var _color = require('../propTypes/color');

var _Responsive = require('../behaviour/Responsive');

var _reactMotion = require('react-motion');

var _Theme = require('../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var simple = function simple(backgroundColor) {
  return (0, _glamor.css)({
    WebkitOverflowScrolling: 'touch',
    overflow: 'auto',
    overflowX: 'hidden',
    backgroundColor: backgroundColor,
    height: '100%'
  });
};

var paddedCss = function paddedCss(paddedVertical, paddedHorizontal, additionalPaddingTop) {
  return (0, _glamor.css)((0, _defineProperty3.default)({}, (0, _Responsive.createMQ)('tablet', 'desktop'), {
    paddingLeft: paddedHorizontal && '25px',
    paddingRight: paddedHorizontal && '25px',
    paddingTop: paddedVertical ? 'calc(25px + ' + additionalPaddingTop + 'px)' : additionalPaddingTop,
    paddingBottom: paddedVertical && '25px'
  }));
};

var SimpleLayout = function (_React$PureComponent) {
  (0, _inherits3.default)(SimpleLayout, _React$PureComponent);

  function SimpleLayout() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, SimpleLayout);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = SimpleLayout.__proto__ || (0, _getPrototypeOf2.default)(SimpleLayout)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      pullDownOffset: 0
    }, _this.trigger = true, _this.scrollTop = 0, _this.pullDown = false, _this.handleScroll = function (e) {
      e.preventDefault();
      _this.scrollTop = e.target.scrollTop;
      if (_this.props.onScrollEnd) {
        var element = e.target;
        var thresholdReached = element.scrollTop + element.clientHeight + 75 > element.scrollHeight;

        // Trigger the callback with a tolerance of 75 px.
        if (thresholdReached) {
          if (_this.trigger) {
            _this.props.onScrollEnd();
            _this.trigger = false;
          }
        } else {
          _this.trigger = true;
        }
      }
    }, _this.handleTouchStart = function (e) {
      if (_this.props.onPullDown && _this.scrollTop === 0) {
        _this.pullDown = false;
        _this.setState({ pullDownOffset: 0 });
      }
    }, _this.handleTouchMove = function (event) {
      if (_this.props.onPullDown && _this.scrollTop === 0) {
        var y = 0;
        var touches = event.touches && event.touches.length ? event.touches : [event];
        var e = event.changedTouches && event.changedTouches[0] || touches[0];
        if (e) {
          y = e.clientY || e.pageY || 0;
        }
        if (_this.pullDown && y > _this.pullDown) {
          _this.setState({
            pullDownOffset: Math.min(Math.abs(_this.pullDown - y), 60)
          });
        } else {
          _this.pullDown = y;
        }
      }
    }, _this.handleTouchEnd = function (e) {
      if (_this.state.pullDownOffset === 60) {
        _this.props.onPullDown();
      }
      if (_this.props.onPullDown && _this.scrollTop === 0) {
        _this.pullDown = false;
        _this.setState({ pullDownOffset: 0 });
      }
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(SimpleLayout, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          children = _props.children,
          backgroundColor = _props.backgroundColor,
          padded = _props.padded,
          props = (0, _objectWithoutProperties3.default)(_props, ['children', 'backgroundColor', 'padded']);


      return _react2.default.createElement(
        _Theme2.default,
        null,
        function (_ref2) {
          var colorize = _ref2.colorize;
          return _react2.default.createElement(
            _reactMotion.Motion,
            {
              defaultStyle: { x: 0 },
              style: { x: (0, _reactMotion.spring)(_this2.state.pullDownOffset) }
            },
            function (value) {
              return _react2.default.createElement(
                _View2.default,
                (0, _extends3.default)({
                  direction: 'column',
                  flex: 'flex',
                  onTouchStart: _this2.handleTouchStart,
                  onTouchMove: _this2.handleTouchMove,
                  onTouchEnd: _this2.handleTouchEnd
                }, simple(colorize(backgroundColor)), paddedCss(padded === true || padded === 'vertical', padded === true || padded === 'horizontal', value.x), {
                  onScroll: _this2.handleScroll
                  // for e2e-tests, to scroll down on pages (id is taken for cross browser selector compat)
                  , id: 'scroll-container'
                }, props),
                children
              );
            }
          );
        }
      );
    }
  }]);
  return SimpleLayout;
}(_react2.default.PureComponent);

SimpleLayout.propTypes = {
  children: _propTypes2.default.node,
  backgroundColor: _color.color,
  follow: _propTypes2.default.bool,
  padded: _propTypes2.default.oneOfType([_propTypes2.default.bool, _propTypes2.default.oneOf(['horizontal', 'vertical'])]),
  onScrollEnd: _propTypes2.default.func,
  onPullDown: _propTypes2.default.func
};
SimpleLayout.defaultProps = {
  backgroundColor: 'background'
};
exports.default = SimpleLayout;