'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Circle = require('../atoms/Circle');

var _Circle2 = _interopRequireDefault(_Circle);

var _reactMotion = require('react-motion');

var _ThemeProvider = require('../behaviour/ThemeProvider');

var _Icon = require('../atoms/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Checkmark = function (_React$Component) {
  (0, _inherits3.default)(Checkmark, _React$Component);

  function Checkmark() {
    (0, _classCallCheck3.default)(this, Checkmark);
    return (0, _possibleConstructorReturn3.default)(this, (Checkmark.__proto__ || (0, _getPrototypeOf2.default)(Checkmark)).apply(this, arguments));
  }

  (0, _createClass3.default)(Checkmark, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          checked = _props.checked,
          theme = _props.theme,
          onClick = _props.onClick,
          props = (0, _objectWithoutProperties3.default)(_props, ['checked', 'theme', 'onClick']);

      return _react2.default.createElement(
        _reactMotion.Motion,
        {
          defaultStyle: { size: 21.5 },
          style: {
            size: (0, _reactMotion.spring)(checked ? 21.5 : 10, {
              stiffness: 180,
              damping: 12
            })
          }
        },
        function (style) {
          return _react2.default.createElement(
            _Circle2.default,
            (0, _extends3.default)({
              outline: true,
              fill: checked,
              outlineColor: _this2.props.disabled ? 'grey' : theme.primary,
              color: _this2.props.disabled ? 'grey' : theme.primary,
              onClick: _this2.props.disabled ? null : onClick
            }, props),
            checked && _react2.default.createElement(_Icon2.default, { size: style.size, name: 'CheckFilledIcon', color: 'white' })
          );
        }
      );
    }
  }]);
  return Checkmark;
}(_react2.default.Component);

Checkmark.propTypes = {
  checked: _propTypes2.default.bool,
  disabled: _propTypes2.default.bool,
  theme: _propTypes2.default.object.isRequired,
  onClick: _propTypes2.default.func
};
Checkmark.defaultProps = {
  checked: false
};
exports.default = (0, _ThemeProvider.withTheme)()(Checkmark);