'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.backgroundColorCode = exports.textColorCode = exports.colorCode = exports.textColor = exports.color = undefined;

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _colors = require('@allthings/colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var color = exports.color = _propTypes2.default.oneOfType([_propTypes2.default.string, // keep this until we got the colors right
_propTypes2.default.oneOf((0, _keys2.default)(_colors.ColorPalette).concat((0, _values2.default)(_colors.ColorPalette)))]);

var textColor = exports.textColor = _propTypes2.default.oneOfType([_propTypes2.default.string, // keep this until we got the colors right
_propTypes2.default.oneOf((0, _keys2.default)(_colors.ColorPalette.text).concat((0, _values2.default)(_colors.ColorPalette.text)))]);

var colorCode = exports.colorCode = function colorCode(color) {
  return _colors.ColorPalette[color] || _colors.ColorPalette.text[color] || color;
};

var textColorCode = exports.textColorCode = function textColorCode(color) {
  return _colors.ColorPalette.text[color] || _colors.ColorPalette[color] || color;
};

var backgroundColorCode = exports.backgroundColorCode = function backgroundColorCode(color) {
  return _colors.ColorPalette.background[color] || _colors.ColorPalette[color] || color;
};