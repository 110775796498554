"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IosDownloadOutlineIcon = function IosDownloadOutlineIcon(props) {
  return _react2.default.createElement(
    "svg",
    _extends({ viewBox: "0 0 512 512" }, props),
    _react2.default.createElement("path", { d: "M288 144v16h112v272H112V160h112v-16H96v304h320V144z" }),
    _react2.default.createElement("path", { d: "M193.1 252.3l-11.6 11.6 74.5 74.5 74.5-74.5-11.6-11.6-54.7 54.7V64h-16.4v243z" })
  );
};

exports.default = IosDownloadOutlineIcon;