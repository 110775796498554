'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Theme = require('../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

var _ListItem = require('./List/ListItem');

var _ListItem2 = _interopRequireDefault(_ListItem);

var _Input = require('../atoms/Input');

var _Input2 = _interopRequireDefault(_Input);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * TextInputs are used to allow users to enter information like names, numbers, urls, email addresses or passwords.
 *
 * ```example
 * <TextInput name="email" type="email" placeholder="Your email" required />
 * <TextInput name="inquiry" lines={5} placeholder="Your question" maxLength={255} minLength={50} />
 * ```
 */
var TextInput = function (_React$Component) {
  (0, _inherits3.default)(TextInput, _React$Component);

  function TextInput() {
    (0, _classCallCheck3.default)(this, TextInput);
    return (0, _possibleConstructorReturn3.default)(this, (TextInput.__proto__ || (0, _getPrototypeOf2.default)(TextInput)).apply(this, arguments));
  }

  (0, _createClass3.default)(TextInput, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          backgroundColor = _props.backgroundColor,
          props = (0, _objectWithoutProperties3.default)(_props, ['backgroundColor']);


      return _react2.default.createElement(
        _Theme2.default,
        null,
        function (_ref) {
          var theme = _ref.theme,
              colorize = _ref.colorize;
          return _react2.default.createElement(
            _ListItem2.default,
            { padded: false, backgroundColor: colorize(backgroundColor) },
            _react2.default.createElement(_Input2.default, props)
          );
        }
      );
    }
  }]);
  return TextInput;
}(_react2.default.Component);

TextInput.propTypes = (0, _extends3.default)({}, _Input2.default.propTypes, {
  /** Background color of the form item */
  backgroundColor: _propTypes2.default.string
});
exports.default = TextInput;