'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _glamor = require('glamor');

var _math = require('./utils/math');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var menuStyle = (0, _glamor.css)({
  position: 'absolute',
  right: 0,
  top: 0,
  background: '#fff',
  boxShadow: '0 1px 3px rgba(0, 0, 0, .38)',
  minWidth: 160,
  '> *': {
    borderTop: '1px solid #ecf0f1',
    padding: '10px 20px',
    cursor: 'pointer'
  },
  '> :hover': {
    background: '#f9f9f9'
  },
  '> :first-child': {
    borderTop: 0
  }
});

var OverlayMenu = function (_React$PureComponent) {
  (0, _inherits3.default)(OverlayMenu, _React$PureComponent);

  function OverlayMenu() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, OverlayMenu);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = OverlayMenu.__proto__ || (0, _getPrototypeOf2.default)(OverlayMenu)).call.apply(_ref, [this].concat(args))), _this), _this.setMenu = function (menu) {
      return _this.menu = menu;
    }, _this.handleDocumentClick = function (e) {
      if (_this.menu) {
        var _this$menu$getBoundin = _this.menu.getBoundingClientRect(),
            bottom = _this$menu$getBoundin.bottom,
            left = _this$menu$getBoundin.left,
            right = _this$menu$getBoundin.right,
            top = _this$menu$getBoundin.top;

        if (!(0, _math.between)(e.clientY, top, bottom) || !(0, _math.between)(e.clientX, left, right)) {
          _this.props.onRequestClose(e);
        }
      }
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(OverlayMenu, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      document.addEventListener('click', this.handleDocumentClick, true);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      document.removeEventListener('click', this.handleDocumentClick, true);
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        (0, _extends3.default)({ ref: this.setMenu }, menuStyle),
        this.props.children
      );
    }
  }]);
  return OverlayMenu;
}(_react2.default.PureComponent);

OverlayMenu.propTypes = {
  onRequestClose: _propTypes2.default.func.isRequired,
  children: _propTypes2.default.node
};
exports.default = OverlayMenu;