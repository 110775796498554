'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(_ref) {
  var horizontal = _ref.horizontal,
      vertical = _ref.vertical;
  return (0, _glamor.css)({
    paddingLeft: horizontal && 15,
    paddingRight: horizontal && 15,
    paddingTop: vertical && 15,
    paddingBottom: vertical && 15
  });
};

var Inset = function Inset(_ref2) {
  var horizontal = _ref2.horizontal,
      vertical = _ref2.vertical,
      props = (0, _objectWithoutProperties3.default)(_ref2, ['horizontal', 'vertical']);
  return _react2.default.createElement(_View2.default, (0, _extends3.default)({}, styles({ horizontal: horizontal, vertical: vertical }), props));
};

Inset.propTypes = {
  vertical: _propTypes2.default.bool,
  horizontal: _propTypes2.default.bool
};

Inset.defaultProps = {
  horizontal: true
};

exports.default = Inset;