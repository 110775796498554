import PropTypes from 'prop-types'
import React from 'react'
import 'babel-polyfill'
import { connect } from 'redux-zero/react'
import actions from './actions'
import AppMaintenancePage from './pages/AppMaintenancePage'
import NoAccessPage from './pages/NoAccessPage'
import AppRouter from './router/Router'
import { BrowserRouter } from 'react-router-dom'

import {
  FormValidityProvider,
  ListSpinner,
  Spacer,
  View,
} from '@allthings/elements'
import NotificationBubbleManager from '@allthings/elements/behaviour/NotificationBubbleManager'

import NotificationBubble from './components/NotificationBubble'

class App extends React.Component {
  constructor(props) {
    console.log('Props:', props)
    super(props)
  }

  reloadPage = () => {
    /* global location */
    location && location.reload()
  }

  componentDidMount() {
    this.props.setAppStatus('ready')
    this.props.setTenantOID(this.props.tenantOID)
  }

  renderBubble = (props) => {
    return <NotificationBubble {...props} />
  }

  renderContent() {
    const { tenantOID, appStatus } = this.props
    console.log(this.props)

    if (appStatus !== 'pending' && (!tenantOID || tenantOID === '')) {
      return <NoAccessPage />
    } else if (appStatus === 'error') {
      return <AppMaintenancePage onLogoClick={this.reloadPage} />
    } else if (appStatus === 'pending') {
      return (
        <View fill alignH="center" alignV="center">
          <Spacer height={20} />
          <ListSpinner />
        </View>
      )
    } else {
      return (
        <FormValidityProvider validity={this.validity}>
          <AppRouter />
        </FormValidityProvider>
      )
    }
  }

  render() {
    return (
      <NotificationBubbleManager
        style={{ height: '100%' }}
        renderBubble={this.renderBubble}
      >
        <BrowserRouter basename="/mieterdossier">
          {this.renderContent()}
        </BrowserRouter>
      </NotificationBubbleManager>
    )
  }
}

/** Validierung/Beschränkungen der Inputs */
App.propTypes = {
  /** OID des Mieters */
  tenantOID: PropTypes.string,
  /** Sprache des Mieters */
  locale: PropTypes.string,
  appStatus: PropTypes.string,
}

const mapToProps = (state) => {
  return {
    tenantOID: state.tenantOID,
    locale: state.locale,
    appStatus: state.appStatus,
  }
}

export default connect(mapToProps, actions)(App)
