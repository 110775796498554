import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import ImageColumn from '../components/ImageColumn'
import FullPage from '../components/FullPage'
import { withRouter } from 'react-router-dom'
import { Button, Spacer, Text } from '@allthings/elements'

import { PV_ASSETS_URL } from '../helper/api-config'
class NoAccessPage extends React.Component {
  static propTypes = {
    btnTarget: PropTypes.string,
    history: PropTypes.object,
    onLogoClicked: PropTypes.func,
    userAgent: PropTypes.string,
  }

  static defaultProps = {
    onLogoClicked: (_) => _,
    onButtonClicked: (_) => _,
  }

  render() {
    return (
      <FullPage onLogoClick={this.props.onLogoClicked}>
        <Text size="xl" align="center">
          <FormattedMessage
            id="no-content.title"
            description="Inform the user, that there are no entries at the moment."
            defaultMessage="There are no entries right now."
          />
        </Text>
        <Spacer height={40} />
        <ImageColumn
          source={`${PV_ASSETS_URL}/img/error_not_found.svg`}
          width="100%"
          height="100%"
        />
        <Spacer height={40} />
        <Text align="center">
          <FormattedHTMLMessage
            id="no-content.text"
            description="Inform the user, that he/she can contact the administrator."
            defaultMessage="Questions?<br />Please contact your administrator."
          />
        </Text>
        <Spacer height={20} />
      </FullPage>
    )
  }
}

export default withRouter(NoAccessPage)
