'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

exports.default = CardButton;

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _View = require('../../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _glamor = require('glamor');

var _kewler = require('kewler');

var _Theme = require('../../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var style = function style(backgroundColor) {
  return (0, _glamor.css)({
    backgroundColor: backgroundColor,
    transition: '500ms ease-in-out',
    ':hover': {
      cursor: 'pointer',
      background: (0, _kewler.color)(backgroundColor, (0, _kewler.lightness)(-10))
    }
  });
};

/**
 * CardButton can to enable users to do actions directly related to content on
 * on cards. It should always go into a [CardFooter](CardFooter.md).
 */
function CardButton(_ref) {
  var children = _ref.children,
      _ref$onClick = _ref.onClick,
      onClick = _ref$onClick === undefined ? function (noop) {
    return noop;
  } : _ref$onClick,
      _ref$backgroundColor = _ref.backgroundColor,
      backgroundColor = _ref$backgroundColor === undefined ? '#ffffff' : _ref$backgroundColor,
      props = (0, _objectWithoutProperties3.default)(_ref, ['children', 'onClick', 'backgroundColor']);

  return _react2.default.createElement(
    _Theme2.default,
    null,
    function (_ref2) {
      var colorize = _ref2.colorize;
      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({
          alignH: 'center',
          flex: 'flex',
          alignV: 'center',
          direction: 'row',
          onClick: onClick
        }, style(colorize(backgroundColor)), props),
        children
      );
    }
  );
}

CardButton.propTypes = {
  children: _propTypes2.default.node,
  /** Callback when button is clicked **/
  onClick: _propTypes2.default.func,
  /** Color of the button **/
  backgroundColor: _propTypes2.default.string
};