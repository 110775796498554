'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createMQ = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _json2mq = require('json2mq');

var _json2mq2 = _interopRequireDefault(_json2mq);

var _glamor = require('glamor');

var _reactMedia = require('react-media');

var _reactMedia2 = _interopRequireDefault(_reactMedia);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MQ = {
  mobile: { maxWidth: 640 },
  tablet: { minWidth: 641, maxWidth: 930 },
  desktop: { minWidth: 931 }
};

var createMQ = exports.createMQ = function createMQ() {
  for (var _len = arguments.length, devices = Array(_len), _key = 0; _key < _len; _key++) {
    devices[_key] = arguments[_key];
  }

  devices = Array.isArray(devices[0]) ? devices[0] : [].concat((0, _toConsumableArray3.default)(devices));

  return '@media ' + (0, _json2mq2.default)(devices.map(function (device) {
    return MQ[device];
  }));
};

var displayStyle = function displayStyle(mq) {
  return (0, _glamor.css)((0, _defineProperty3.default)({}, '@media ' + mq, {
    display: 'none !important'
  }));
};

var Responsive = function (_React$Component) {
  (0, _inherits3.default)(Responsive, _React$Component);

  function Responsive() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Responsive);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Responsive.__proto__ || (0, _getPrototypeOf2.default)(Responsive)).call.apply(_ref, [this].concat(args))), _this), _this.renderChildrenFn = function () {
      return _this.props.children;
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Responsive, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          mobile = _props.mobile,
          tablet = _props.tablet,
          desktop = _props.desktop,
          onlyRenderOnMatch = _props.onlyRenderOnMatch,
          children = _props.children;

      var bp = [];

      if (onlyRenderOnMatch) {
        mobile && bp.push(MQ.mobile);
        tablet && bp.push(MQ.tablet);
        desktop && bp.push(MQ.desktop);

        return _react2.default.createElement(_reactMedia2.default, { query: (0, _json2mq2.default)(bp), render: this.renderChildrenFn });
      } else {
        // negate media queries because the logic inverts when using `display: none`
        !mobile && bp.push(MQ.mobile);
        !tablet && bp.push(MQ.tablet);
        !desktop && bp.push(MQ.desktop);
        var style = displayStyle((0, _json2mq2.default)(bp));

        return _react2.default.createElement(
          _View2.default,
          style,
          children
        );
      }
    }
  }]);
  return Responsive;
}(_react2.default.Component);

Responsive.propTypes = {
  /** Children to be displayed when component matches screen size(s) */
  children: _propTypes2.default.node.isRequired,
  /** Whether the component should be displayed on mobile or not */
  mobile: _propTypes2.default.bool,
  /** Whether the component should be displayed on tablet or not */
  tablet: _propTypes2.default.bool,
  /** Whether the component should be displayed on desktop or not */
  desktop: _propTypes2.default.bool,
  /** Ensures that the children will only be rendered on a match, if false it will be hidden by CSS (but still be rendered) */
  onlyRenderOnMatch: _propTypes2.default.bool
};
exports.default = Responsive;