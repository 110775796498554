import { callPriveraApi } from './helper/api'

const actions = store => ({
  setLocale: (state, locale) => ({
    ...state,
    locale: locale,
  }),
  setTenantOID: (state, tenantOID) => ({
    ...state,
    tenantOID: tenantOID,
  }),
  setAppStatus: (state, stat) => ({
    ...state,
    appStatus: stat,
  }),
  setTenantsLoading: (state, stat) => ({
    ...state,
    tenants: Object.assign(
      {},
      {
        tenants: [],
      },
      {
        loading: true,
      }
    ),
  }),
  setFormLoading: (state, val) => ({
    ...state,
    form: {
      loading: val,
    },
  }),

  // Set userData at privera
  setTenantData: async (state, data) => {
    store.setState({
      form: {
        pending: true,
      },
    })
    const payload = await callPriveraApi({
      method: 'PATCH',
      path: `tenants/${state.tenantOID}`,
      locale: state.locale,
      data,
    })
    return {
      form: {
        status: payload.status,
        message: payload.message,
        pending: false,
      },
      tenant: Object.assign(
        {},
        payload.status === 'success'
          ? {
              ...payload.data,
            }
          : {
              ...state.tenant,
            },
        {
          loading: false,
        }
      ),
    }
  },
  // get tenantData from privera
  getTenant: async state => {
    const payload = await callPriveraApi({
      method: 'GET',
      path: `tenants/${state.tenantOID}`,
      locale: state.locale,
    })
    return {
      tenant: Object.assign(
        {},
        payload.status === 'error'
          ? {
              status: 'error',
            }
          : {
              ...payload.data,
            },
        {
          loading: false,
        }
      ),
    }
  },
  // get accountData from privera
  getTenantAccount: async state => {
    const payload = await callPriveraApi({
      method: 'GET',
      path: `tenants/${state.tenantOID}/account`,
      locale: state.locale,
    })
    return {
      account: Object.assign(
        {},
        {
          ...payload.data,
        },
        {
          loading: false,
        }
      ),
    }
  },
  // get tenancyData from privera
  getTenancy: async (state, tenancyID) => {
    const payload = await callPriveraApi({
      method: 'GET',
      path: `tenancies/${tenancyID}`,
      locale: state.locale,
    })
    return {
      tenancy: Object.assign(
        {},
        {
          ...payload.data,
        },
        {
          loading: false,
        }
      ),
    }
  },
  // get tenancies contactPersons from privera
  getContactPersons: async (state, tenancyID) => {
    const payload = await callPriveraApi({
      method: 'GET',
      path: `tenancies/${tenancyID}/contacts`,
      locale: state.locale,
    })
    return {
      contacts: Object.assign(
        {},
        {
          persons: payload.data,
        },
        {
          loading: false,
        }
      ),
    }
  },
  // get tenancies tenants from privera
  getTenants: async (state, tenancyID) => {
    const myID = state.tenantOID
    const payload = await callPriveraApi({
      method: 'GET',
      path: `tenancies/${tenancyID}/tenants`,
      locale: state.locale,
    })
    const tenants = payload.data.filter(tenant => {
      return tenant.id !== myID
    })
    return {
      tenants: Object.assign(
        {},
        {
          tenants,
        },
        {
          loading: false,
        }
      ),
    }
  },
})

export default actions
