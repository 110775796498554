import createStore from 'redux-zero'

const initialState = {
  account: {
    loading: true,
  },
  appStatus: 'pending',
  contacts: {
    persons: [],
    loading: true,
  },
  tenantOID: '',
  locale: '',
  tenancy: {
    loading: true,
  },
  tenant: {
    loading: true,
  },
  tenants: {
    tenants: [],
    loading: true,
  },
  form: {
    loading: true,
    pending: false,
  },
}

const store = createStore(initialState)

export default store
