'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Circle = require('../atoms/Circle');

var _Circle2 = _interopRequireDefault(_Circle);

var _Text = require('../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Absolute = require('../atoms/Absolute');

var _Absolute2 = _interopRequireDefault(_Absolute);

var _Theme = require('../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * CountIndicator are used to indicated changes or updates. They can also be
 * used to inform user about new or unseen information that are available
 *
 * ```example
 * <ThemeProvider>
 *   <Relative>
 *     <Text>Hello</Text>
 *     <CountIndicator top={0} left={35} count={123} />
 *   </Relative>
 * </ThemeProvider>
 * ```
 **/
var CountIndicator = function CountIndicator(_ref) {
  var count = _ref.count,
      props = (0, _objectWithoutProperties3.default)(_ref, ['count']);
  return count === 0 ? null : _react2.default.createElement(
    _Theme2.default,
    null,
    function (_ref2) {
      var colorize = _ref2.colorize;
      return _react2.default.createElement(
        _Absolute2.default,
        props,
        _react2.default.createElement(
          _Circle2.default,
          { color: colorize(props.color), radius: 18 },
          _react2.default.createElement(
            _Text2.default,
            { size: 'xs', color: 'white' },
            count > 9 ? '9+' : count
          )
        )
      );
    }
  );
};

CountIndicator.propTypes = {
  /** Number to display */
  count: _propTypes2.default.number.isRequired,
  /** Top offset */
  top: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  /** Bottom offset */
  bottom: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  /** Left offset */
  left: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  /** Right offset */
  right: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  /** Color */
  color: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number])
};

CountIndicator.defaultProps = {
  color: 'warn'
};

exports.default = CountIndicator;