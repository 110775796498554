'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _glamor = require('glamor');

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

var _Responsive = require('../behaviour/Responsive');

var _color = require('../propTypes/color');

var _ThemeProvider = require('../behaviour/ThemeProvider');

var _Inset = require('../atoms/Inset');

var _Inset2 = _interopRequireDefault(_Inset);

var _Text = require('../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var heroStyle = function heroStyle(background) {
  return (0, _glamor.css)({
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: 232,
    backgroundColor: (0, _color.colorCode)(background),
    position: 'relative',
    boxShadow: '0px -1px 5px rgba(0,0,0,0.3)'
  });
};

var styles = {
  heroImage: (0, _glamor.css)({
    position: 'absolute',
    right: '-150px',
    top: '-70px',
    width: '360px',
    height: '360px'
  }),
  // necessary because the image itself is negatively placed and
  // the flex properties are messed up without a container
  heroImageContainer: (0, _glamor.css)({
    width: '150px'
  }),
  textContainer: (0, _glamor.css)((0, _defineProperty3.default)({
    width: '142px'
  }, (0, _Responsive.createMQ)('tablet', 'desktop'), {
    width: '50%'
  }))

  /**
   * Heros are used to give users an introduction and quickly explain features.
   *
   * ```example
   * <ThemeProvider>
   *     <Hero text="You are my Hero!" img="https://placeimg.com/500/500/people">
   *       <View fill direction="row" alignH="space-between" alignV="stretch">
   *         <Button backgroundColor="rgba(0,0,0,0.2)" color="white">Thank you</Button>
   *       </View>
   *     </Hero>
   * </ThemeProvider>
   * ```
   */
};
var Hero = function (_React$Component) {
  (0, _inherits3.default)(Hero, _React$Component);

  function Hero() {
    (0, _classCallCheck3.default)(this, Hero);
    return (0, _possibleConstructorReturn3.default)(this, (Hero.__proto__ || (0, _getPrototypeOf2.default)(Hero)).apply(this, arguments));
  }

  (0, _createClass3.default)(Hero, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          img = _props.img,
          text = _props.text,
          children = _props.children,
          color = _props.color,
          props = (0, _objectWithoutProperties3.default)(_props, ['img', 'text', 'children', 'color']);

      return _react2.default.createElement(
        _View2.default,
        (0, _extends3.default)({
          direction: 'row',
          alignV: 'center',
          alignH: 'space-around'
        }, heroStyle(color), props),
        _react2.default.createElement(
          _Inset2.default,
          (0, _extends3.default)({ direction: 'column', alignH: 'start' }, styles.textContainer),
          _react2.default.createElement(
            _Text2.default,
            { color: 'white', size: 'l', strong: true },
            text
          ),
          children
        ),
        _react2.default.createElement(
          _View2.default,
          styles.heroImageContainer,
          _react2.default.createElement('img', (0, _extends3.default)({}, styles.heroImage, { src: img }))
        )
      );
    }
  }]);
  return Hero;
}(_react2.default.Component);

Hero.propTypes = {
  /** Color of the hero, will be primary color by default */
  color: _propTypes2.default.string,
  /** Text that will be announced with the Hero */
  text: _propTypes2.default.string,
  /** Additional children, try to avoid */
  children: _propTypes2.default.node,
  /** URL to image that will be rendered */
  img: _propTypes2.default.string
};
Hero.defaultProps = {
  color: 'grey'
};


var mapThemeToProps = function mapThemeToProps(theme) {
  return {
    color: theme.primary
  };
};

exports.default = (0, _ThemeProvider.withTheme)(mapThemeToProps)(Hero);