import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { connect } from 'redux-zero/react'
import actions from '../actions'
import HeaderBar from '../components/HeaderBar'
import { messages } from '../helper/messages'
import { getTenancyTitle, getLocalDate } from '../helper/helpers'
import ListValueItem from '../components/ListValueItem'
import {
  GroupedCardList,
  ListSpinner,
  SimpleLayout,
  Spacer,
  Text,
  View,
} from '@allthings/elements'
import DownloadIcon from '@allthings/react-ionicons/lib/IosDownloadOutlineIcon'

const tenancyKeys = [
  'contract_begin',
  'notice_type',
  'notice_period',
  'ancillary_cost_period',
  'billing_model',
  'deposit',
  'deposit_type',
  'deposit_adress',
]

class Tenancy extends React.Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    tenancy: PropTypes.object.isRequired,
    getTenancy: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getTenancy(this.props.match.params.id)
  }

  formatMessage = (message, options) =>
    this.props.intl.formatMessage(message, options)

  getAddress(tenancy) {
    if (
      tenancy.deposit_name ||
      tenancy.deposit_street ||
      tenancy.deposit_city
    ) {
      return (
        <Text align="right">
          <Text>{tenancy.deposit_name}</Text>
          <Text>{tenancy.deposit_street}</Text>
          <Text>
            {tenancy.deposit_zip} {tenancy.deposit_city}
          </Text>
        </Text>
      )
    } else {
      return <Text align="right">--</Text>
    }
  }

  renderTenancy() {
    const { tenancy, locale } = this.props

    return tenancy && tenancy.id.length ? (
      <GroupedCardList title={getTenancyTitle(this.formatMessage, tenancy)}>
        {tenancyKeys.map((key, i) => {
          const val =
            key === 'contract_begin'
              ? getLocalDate(tenancy[key], locale)
              : key === 'deposit_adress'
              ? this.getAddress(tenancy)
              : tenancy[key] || '--'

          return (
            <ListValueItem key={key} label={this.formatMessage(messages[key])}>
              <Text>{val}</Text>
            </ListValueItem>
          )
        })}
      </GroupedCardList>
    ) : null
  }

  renderDownload() {
    const { tenancy } = this.props

    return tenancy.inspection_protocol && tenancy.inspection_protocol.length ? (
      <GroupedCardList title={this.formatMessage(messages.download)}>
        <ListValueItem label={this.formatMessage(messages.inspection_protocol)}>
          <a href={tenancy.inspection_protocol} target="_blank" rel="noopener">
            <DownloadIcon
              style={{
                width: 18,
                height: 18,
                margin: '0 5',
              }}
            />
          </a>
        </ListValueItem>
      </GroupedCardList>
    ) : null
  }

  renderRent() {
    const { tenancy } = this.props

    return tenancy.rent_composition && tenancy.rent_composition.length ? (
      <GroupedCardList title={this.formatMessage(messages.rent_composition)}>
        {tenancy.rent_composition.map((rent, i) => {
          return (
            <ListValueItem key={rent.name} label={rent.name}>
              <Text>{rent.value}</Text>
            </ListValueItem>
          )
        })}
        <strong>
          <ListValueItem label={this.formatMessage(messages.rent_total)}>
            <Text>{tenancy.rent_total}</Text>
          </ListValueItem>
        </strong>
      </GroupedCardList>
    ) : null
  }

  renderContent() {
    const { tenancy } = this.props

    if (tenancy.loading) {
      return (
        <View fill alignH="center" alignV="center">
          <Spacer height={20} />
          <ListSpinner />
        </View>
      )
    } else {
      return (
        <Fragment>
          {this.renderTenancy()}
          {this.renderRent()}
          {this.renderDownload()}
        </Fragment>
      )
    }
  }

  render() {
    return (
      <Fragment>
        <HeaderBar
          label={this.formatMessage(messages.tenancyHeader)}
          lBtnIcon="arrow-left"
          lBtnTarget="/"
        />
        <SimpleLayout flex="auto">{this.renderContent()}</SimpleLayout>
      </Fragment>
    )
  }
}

const mapToProps = (state, ownProps) => {
  return {
    tenancy: state.tenancy,
    locale: state.locale,
  }
}

export default injectIntl(connect(mapToProps, actions)(Tenancy))
