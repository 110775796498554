'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = alpha;

var _hexToRgb = require('./hexToRgb');

var _hexToRgb2 = _interopRequireDefault(_hexToRgb);

var _rgbToHex = require('./rgbToHex');

var _rgbToHex2 = _interopRequireDefault(_rgbToHex);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function calculateTransparentColor(color, alpha) {
  var blendingColor = {
    r: 255,
    g: 255,
    b: 255
  };

  return (0, _rgbToHex2.default)((1 - alpha) * blendingColor.r + alpha * color.r, (1 - alpha) * blendingColor.g + alpha * color.g, (1 - alpha) * blendingColor.b + alpha * color.b);
}

function alpha(hex, alpha, actualColor) {
  var rgb = (0, _hexToRgb2.default)(hex);
  if (rgb === null) {
    throw new Error('`hex` value provided to `alpha` is not a HEX color, `' + hex + '` given.');
  }

  return actualColor ? calculateTransparentColor(rgb, alpha) : 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + alpha + ')';
}