import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { css } from 'glamor'

import IosClockOutlineIcon from '@allthings/react-ionicons/lib/IosClockOutlineIcon'

import ImageColumn from '../components/ImageColumn'
import TextColumn from '../components/TextColumn'
import FullPage from '../components/FullPage'

const styles = {
  container: css({
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    marginRight: 80,
    left: 40,
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    backgroundColor: '#fff',
  }),
  clock: css({
    cursor: 'pointer',
    width: 128,
    height: 128,
  }),
}

class AppMaintenancePage extends React.Component {
  static propTypes = {
    userAgent: PropTypes.string,
    onLogoClick: PropTypes.func.isRequired,
  }

  render() {
    return (
      <FullPage onLogoClick={this.props.onLogoClick}>
        <ImageColumn>
          <IosClockOutlineIcon
            {...styles.clock}
            onClick={this.props.onLogoClick}
          />
        </ImageColumn>
        <TextColumn onClick={this.props.onLogoClick}>
          <h1 style={{ textAlign: 'initial', fontSize: 36 }}>
            <FormattedMessage
              id="maintenance.title"
              description="Greet the user, when things go wrong"
              defaultMessage="We are back in a minute!"
            />
          </h1>
          <div>
            <p>
              <FormattedMessage
                id="maintenance.message"
                description="Explain to the user, that the app will be back available soon"
                defaultMessage="Things go wild sometimes. We are working on getting the microapp available for you – so come back as soon as possible."
              />
            </p>
          </div>
        </TextColumn>
      </FullPage>
    )
  }
}

export default AppMaintenancePage
