'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Image = require('./Image');

var _Image2 = _interopRequireDefault(_Image);

var _View = require('../atoms/View');

var _View2 = _interopRequireDefault(_View);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * The Allthings logo
 *
 * ```example
 * <ThemeProvider>
 *  <View style={{ background: '#bada55', padding: 15 }}>
 *    <Relative>
 *      <Logo size={80} onClick={this.handleClick} />
 *      <CountIndicator top="55" left="50" count={this.state.count} />
 *    </Relative>
 *  </View>
 *</ThemeProvider>
 */
var Logo = function (_React$Component) {
  (0, _inherits3.default)(Logo, _React$Component);

  function Logo() {
    (0, _classCallCheck3.default)(this, Logo);
    return (0, _possibleConstructorReturn3.default)(this, (Logo.__proto__ || (0, _getPrototypeOf2.default)(Logo)).apply(this, arguments));
  }

  (0, _createClass3.default)(Logo, [{
    key: 'render',
    value: function render() {
      var _height$width = {
        height: 'auto', // 219
        width: this.props.size // 187
      },
          height = _height$width.height,
          width = _height$width.width;


      return _react2.default.createElement(
        _View2.default,
        { style: { height: height, width: width }, onClick: this.props.onClick },
        _react2.default.createElement(_Image2.default, {
          height: '100%',
          width: '100%',
          src: '/static/img/allthings-logo.svg',
          alt: 'Allthings'
        })
      );
    }
  }]);
  return Logo;
}(_react2.default.Component);

Logo.propTypes = {
  /** Callback when user clicks the logo */
  onClick: _propTypes2.default.func,
  /** Size of the number in pixels */
  size: _propTypes2.default.number
};
exports.default = Logo;