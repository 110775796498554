'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _glamor = require('glamor');

var _Text = require('../atoms/Text');

var _Text2 = _interopRequireDefault(_Text);

var _ProfileImage = require('../molecules/ProfileImage');

var _ProfileImage2 = _interopRequireDefault(_ProfileImage);

var _Theme = require('../behaviour/Theme');

var _Theme2 = _interopRequireDefault(_Theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
  reply: (0, _glamor.css)({ padding: '0 15px 15px 15px' }),
  title: (0, _glamor.css)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }),
  titleReversed: (0, _glamor.css)({
    flexDirection: 'row-reverse'
  }),
  user: (0, _glamor.css)({
    fontWeight: 'bold',
    opacity: 0.5
  }),
  time: (0, _glamor.css)({
    opacity: 0.5
  }),
  content: (0, _glamor.css)({
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }),
  contentReversed: (0, _glamor.css)({
    flexDirection: 'row-reverse'
  }),
  image: (0, _glamor.css)({ flexShrink: 0 }),
  outerText: function outerText(background) {
    return (0, _glamor.css)({
      paddingLeft: 15,
      flex: 1,
      position: 'relative',
      ':before': {
        content: '""',
        background: 'transparent',
        position: 'absolute',
        left: 10,
        top: 15,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '6px 5px 6px 0',
        borderColor: 'transparent ' + background + ' transparent transparent'
      }
    });
  },
  outerTextReversed: function outerTextReversed(background) {
    return (0, _glamor.css)({
      paddingLeft: 0,
      paddingRight: 15,
      ':before': {
        left: 'initial',
        right: 10,
        borderWidth: '6px 0 6px 5px',
        borderColor: 'transparent transparent transparent ' + background
      }
    });
  },
  textContainer: (0, _glamor.css)({
    padding: 10,
    borderRadius: 6,
    boxShadow: '2px 3px 3px 0px rgba(211, 211, 211, .25)'
  }),
  text: (0, _glamor.css)({
    whiteSpace: 'pre-wrap',
    '& a': {
      color: '#fff',
      textDecoration: 'underline'
    }
  })

  /**
   * ChatBubble are used to show conversations between to or more users.
   *
   * ```example
   * <ThemeProvider>
   *   <View>
   *     <ChatBubble
   *       text="Hello World"
   *       userName="You"
   *       userImage="https://placeimg.com/40/40/people?t=3"
   *       date="a minute ago"
   *     />
   *     <ChatBubble
   *       direction="right"
   *       text="Hello You"
   *       userName="Agent"
   *       userImage="https://placeimg.com/40/40/people?t=1"
   *       date="just now"
   *     />
   *   </View>
   * </ThemeProvider>
   * ```
   */
};
var ChatBubble = function (_React$Component) {
  (0, _inherits3.default)(ChatBubble, _React$Component);

  function ChatBubble() {
    (0, _classCallCheck3.default)(this, ChatBubble);
    return (0, _possibleConstructorReturn3.default)(this, (ChatBubble.__proto__ || (0, _getPrototypeOf2.default)(ChatBubble)).apply(this, arguments));
  }

  (0, _createClass3.default)(ChatBubble, [{
    key: 'renderProfileImage',
    value: function renderProfileImage() {
      var userImage = this.props.userImage;

      return _react2.default.createElement(_ProfileImage2.default, { size: 'medium', image: userImage });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          userName = _props.userName,
          date = _props.date,
          background = _props.background,
          direction = _props.direction,
          text = _props.text;

      var isReversed = direction === 'right';

      return _react2.default.createElement(
        _Theme2.default,
        null,
        function (_ref) {
          var theme = _ref.theme,
              colorize = _ref.colorize;
          return _react2.default.createElement(
            'div',
            styles.reply,
            _react2.default.createElement(
              'div',
              (0, _extends3.default)({
                className: 'info'
              }, (0, _glamor.css)(styles.title, isReversed && styles.titleReversed)),
              _react2.default.createElement(
                _Text2.default,
                (0, _extends3.default)({ size: 's' }, styles.user),
                userName
              ),
              _react2.default.createElement(
                _Text2.default,
                (0, _extends3.default)({ size: 's' }, styles.time),
                date
              )
            ),
            _react2.default.createElement(
              'div',
              (0, _extends3.default)({
                className: 'content'
              }, (0, _glamor.css)(styles.content, isReversed && styles.contentReversed)),
              _react2.default.createElement(
                'div',
                (0, _extends3.default)({ className: 'image' }, styles.image),
                _this2.renderProfileImage()
              ),
              _react2.default.createElement(
                'div',
                (0, _glamor.css)(styles.outerText(background), isReversed && styles.outerTextReversed(background)),
                _react2.default.createElement(
                  'div',
                  (0, _glamor.css)(styles.textContainer, {
                    background: background
                  }),
                  _react2.default.createElement(
                    _Text2.default,
                    (0, _glamor.css)(styles.text),
                    text
                  )
                )
              )
            )
          );
        }
      );
    }
  }]);
  return ChatBubble;
}(_react2.default.Component);

ChatBubble.propTypes = {
  /* Name of the person who wrote the message */
  userName: _propTypes2.default.string.isRequired,
  /* Profile image of person who wrote the message */
  userImage: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.object]),
  /* Date when the message was send */
  date: _propTypes2.default.node.isRequired,
  /* Text of the message */
  text: _propTypes2.default.node.isRequired,
  /* Background color of chat message */
  background: _propTypes2.default.string,
  /* Position of profile image and name */
  direction: _propTypes2.default.oneOf(['right', 'left']),
  /* Text color */
  fontColor: _propTypes2.default.string
};
ChatBubble.defaultProps = {
  direction: 'left',
  background: 'white',
  fontColor: 'text'
};
exports.default = ChatBubble;